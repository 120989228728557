import React from "react";
import ScrollActive from "../../components/ScrollActive";
import Topbar from "../../components/Topbar";
import Title from "../../components/Title";
import SubTitle from "../../components/SubTitle";
import PreviousBtn from "../../components/PreviousBtn";
import NextBtn from "../../components/NextBtn";
import RightBar from "../../components/RightBar";
import navigate_omni_channel from "../../images/omnichannelimg/instagramimg/navigate_omni_channel.jpg";
import navigate_instagram from "../../images/omnichannelimg/instagramimg/navigate_instagram.jpg";
import click_connect_instagram from "../../images/omnichannelimg/instagramimg/click_connect_instagram.jpg";
import sign_with_facebook from "../../images/omnichannelimg/instagramimg/sign_with_facebook.jpg";
import login_facebook from "../../images/omnichannelimg/instagramimg/login_facebook.jpg";
import continue_ac_name from "../../images/omnichannelimg/instagramimg/continue_ac_name.jpg";
import click_get_started from "../../images/omnichannelimg/instagramimg/click_get_started.jpg";
import login_into_instagram from "../../images/omnichannelimg/instagramimg/login_into_instagram.jpg";
import select_creator_next from "../../images/omnichannelimg/instagramimg/select_creator_next.jpg";
import click_next from "../../images/omnichannelimg/instagramimg/click_next.jpg";
import choose_businesses_continue from "../../images/omnichannelimg/instagramimg/choose_businesses_continue.jpg";
import review_permission_save from "../../images/omnichannelimg/instagramimg/review_permission_save.jpg";
import click_got_it from "../../images/omnichannelimg/instagramimg/click_got_it.jpg";
import select_istagram_acc_save from "../../images/omnichannelimg/instagramimg/select_istagram_acc_save.jpg";
import click_complete_setup from "../../images/omnichannelimg/instagramimg/click_complete_setup.jpg";
import instagram_successfully from "../../images/omnichannelimg/instagramimg/instagram_successfully.jpg";
import navigate_application from "../../images/omnichannelimg/instagramimg/navigate_application.jpg";
import existing_application from "../../images/omnichannelimg/instagramimg/existing_application.jpg";
import select_bot from "../../images/omnichannelimg/instagramimg/select_bot.jpg";
import click_connect from "../../images/omnichannelimg/instagramimg/click_connect.jpg";
import click_connect_insta from "../../images/omnichannelimg/instagramimg/click_connect_insta.jpg";
import send_nstagram_message from "../../images/omnichannelimg/instagramimg/send_nstagram_message.jpg";
import open_insta_account from "../../images/omnichannelimg/instagramimg/open_insta_account.jpg";
import navigate_live_chat from "../../images/omnichannelimg/instagramimg/navigate_live_chat.jpg";
import open_chat from "../../images/omnichannelimg/instagramimg/open_chat.jpg";

const rightBarMenu = [
  {
    tolink: "step-1-navigate-to-omni-channel",
    submenutext: "Step - 1 Navigate to Omni Channel",
  },
  {
    tolink: "step-2-navigate-to-instagram",
    submenutext: "Step - 2 Navigate to Instagram",
  },
  {
    tolink: "step-3-click-connect",
    submenutext: "Step - 3 Click Connect",
  },
  {
    tolink: "step-4-click-sign-in-with-facebook",
    submenutext: "Step - 4 Click Sign in with Facebook",
  },
  {
    tolink: "step-5-login-to-facebook",
    submenutext: "Step - 5 Login to Facebook",
  },
  {
    tolink: "step-6-click-continue-as-account-name",
    submenutext: "Step - 6 Click Continue as Account name",
  },
  {
    tolink: "step-7-click-started-in-instagram-popup",
    submenutext: "Step - 7 Click started in Instagram popup",
  },
  {
    tolink: "step-8-instagram-credential-and-click-log-in",
    submenutext: "Step - 8 Enter Instagram credential and Click Log in",
  },
  {
    tolink: "step-9-choose-meta-businesses-account",
    submenutext: "Step - 9 Choose meta businesses account",
  },
  {
    tolink: "step-10-xunified-to-access-and-click-continue",
    submenutext: "Step - 10 XUnified to access and Click Continue",
  },
  {
    tolink: "step-11-choose-the-instagram-accounts",
    submenutext: "Step - 11 Choose the Instagram accounts",
  },
  {
    tolink: "step-12-review-what-xunified-is-requesting",
    submenutext: "Step - 12 Review what XUnified is requesting",
  },
  {
    tolink: "step-13-account-name-has-been-connected",
    submenutext: "Step - 13 Account Name has been connected",
  },
  {
    tolink: "step-14-select-your-instagram-account-and-click-save",
    submenutext: "Step - 14 Select your Instagram Account and Click Save",
  },
  {
    tolink: "step-15-click-complete-setup",
    submenutext: "Step - 15 Click Complete Setup",
  },
  {
    tolink: "step-16-navigate-to-application",
    submenutext: "Step - 16 Navigate to Application",
  },
  {
    tolink: "step-17-click-on-existing-application",
    submenutext: "Step - 17 Click on Existing Application",
  },
  {
    tolink: "step-18-select-bot",
    submenutext: "Step - 18 Select Bot",
  },
  {
    tolink: "step-19-connect-bot",
    submenutext: "Step - 19 Connect Bot",
  },
  {
    tolink: "step-20-connect-instagram-account-with-application",
    submenutext: "Step - 20 Connect Instagarm Account with Application",
  },
  {
    tolink: "step-21-send-instagarm-message-on-configured-instagram-account",
    submenutext:
      "Step - 21 Send Instagram message on configured Instagran Account",
  },
  {
    tolink: "step-22-open-configured-instagram-account-messages",
    submenutext: "Step - 22 Open configured Instagram Account messages",
  },
  {
    tolink: "step-23-navigate-to-live-chat",
    submenutext: "Step - 23 Navigate to Live Chat",
  },
  {
    tolink: "step-24-open-the-chat",
    submenutext: "Step - 24 Open the Chat",
  },
];

function InstagramIntegration() {
  return (
    <>
      <ScrollActive rightBarMenu={rightBarMenu} />
      <Topbar rightBarMenu={rightBarMenu} />

      <div className="lg:flex gap-6 sm:p-8 p-5 text-gray-600">
        <div className="flex-1">
          <Title title="Instagram Integration" />

          <div className="mb-8">
            <SubTitle
              subtitle="Step - 1 Navigate to Omni Channel"
              hrefLink="step-1-navigate-to-omni-channel"
            />
            <p className="mb-2">
              Begin by accessing the Omni Channel feature within your platform
              XUnified.
            </p>
            <img
              src={navigate_omni_channel}
              alt="navigate_omni_channel"
              className="border"
            />
          </div>

          <div className="mb-8">
            <SubTitle
              subtitle="Step - 2 Navigate to Instagram"
              hrefLink="step-2-navigate-to-instagram"
            />
            <p className="mb-2">
              Locate and select the Instagram option within the Omni Channel.
            </p>
            <img
              src={navigate_instagram}
              alt="navigate_instagram"
              className="border"
            />
          </div>

          <div className="mb-8">
            <SubTitle
              subtitle="Step - 3 Click Connect"
              hrefLink="step-3-click-connect"
            />
            <p className="mb-2">
              Click the "Connect" button to start linking your Instagram account
              to the platform.
            </p>
            <img
              src={click_connect_instagram}
              alt="click_connect_instagram"
              className="border"
            />
          </div>

          <div className="mb-8">
            <SubTitle
              subtitle="Step - 4 Click Sign in with Facebook, in Connect Instagram Account pop-up"
              hrefLink="step-4-click-sign-in-with-facebook"
            />
            <p className="mb-2">
              In the "Connect Instagram Account" pop-up, choose "Sign in with
              Facebook" for authentication.
            </p>
            <img
              src={sign_with_facebook}
              alt="sign_with_facebook"
              className="border"
            />
          </div>

          <div className="mb-8">
            <SubTitle
              subtitle="Step - 5 Login to Facebook"
              hrefLink="step-5-login-to-facebook"
            />
            <p className="mb-2">
              Click the "Connect" button to start linking your Instagram account
              to the platform.
            </p>
            <img src={login_facebook} alt="login_facebook" className="border" />
          </div>

          <div className="mb-8">
            <SubTitle
              subtitle="Step - 6 Click Continue as “Account name”"
              hrefLink="step-6-click-continue-as-account-name"
            />
            <p className="mb-2">
              Confirm your identity by clicking "Continue" as the displayed
              Facebook account name.
            </p>
            <img
              src={continue_ac_name}
              alt="continue_ac_name"
              className="border"
            />
          </div>

          <div className="mb-8">
            <SubTitle
              subtitle="Step - 7 Click Get started, in connect to Instagram pop-up"
              hrefLink="step-7-click-started-in-instagram-popup"
            />
            <p className="mb-2">
              In the "Connect to Instagram" pop-up, click "Get Started" to
              proceed with the setup.
            </p>
            <img
              src={click_get_started}
              alt="click_get_started"
              className="border"
            />
          </div>

          <div className="mb-8">
            <SubTitle
              subtitle="Step - 8 Enter Instagram credential and Click Log in"
              hrefLink="step-8-instagram-credential-and-click-log-in"
            />
            <p className="mb-2">
              Enter your Instagram username and password, then click "Log in" to
              authenticate.
            </p>
            <img
              src={login_into_instagram}
              alt="login_into_instagram"
              className="border"
            />
          </div>

          <div className="mb-8">
            <SubTitle
              subtitle="Step - 9 Choose the meta businesses account, Click Continue"
              hrefLink="step-9-choose-meta-businesses-account"
            />
            <p className="mb-2">
              Select the Meta Business Account associated with your Instagram
              and click "Continue."
            </p>
            <img
              src={select_creator_next}
              alt="select_creator_next"
              className="border"
            />
          </div>

          <div className="mb-8">
            <SubTitle
              subtitle="Step - 10 Choose the Pages you want XUnified to access and Click Continue"
              hrefLink="step-10-xunified-to-access-and-click-continue"
            />
            <p className="mb-2">
              Select which Facebook Pages linked to your Meta Business Account
              XUnified can access and click "Continue."
            </p>
            <img src={click_next} alt="click_next" className="border" />
          </div>

          <div className="mb-8">
            <SubTitle
              subtitle="Step - 11 Choose the Instagram accounts that you want XUnified to access and Click Continue"
              hrefLink="step-11-choose-the-instagram-accounts"
            />
            <p className="mb-2">
              Choose which Instagram accounts you want XUnified to manage and
              click "Continue."
            </p>
            <img
              src={choose_businesses_continue}
              alt="choose_businesses_continue"
              className="border"
            />
          </div>

          <div className="mb-8">
            <SubTitle
              subtitle="Step - 12 Review what XUnified is requesting permission to do and Click Save"
              hrefLink="step-12-review-what-xunified-is-requesting"
            />
            <p className="mb-2">
              Review the access permissions XUnified is requesting and click
              "Save" to confirm.
            </p>
            <img
              src={review_permission_save}
              alt="review_permission_save"
              className="border"
            />
          </div>

          <div className="mb-8">
            <SubTitle
              subtitle="Step - 13 “Account Name” has been connected to XUnified and Click Got it"
              hrefLink="step-13-account-name-has-been-connected"
            />
            <p className="mb-2">
              Once "[Account Name]" shows as connected to XUnified, click "Got
              it" to acknowledge the successful connection.
            </p>
            <img src={click_got_it} alt="click_got_it" className="border" />
          </div>

          <div className="mb-8">
            <SubTitle
              subtitle="Step - 14 Select your Instagram Account and Click Save"
              hrefLink="step-14-select-your-instagram-account-and-click-save"
            />
            <p className="mb-2">
              Choose the specific Instagram account you wish to use and click
              "Save" to finalize the selection.
            </p>
            <img
              src={select_istagram_acc_save}
              alt="select_istagram_acc_save"
              className="border"
            />
          </div>

          <div className="mb-8">
            <SubTitle
              subtitle="Step - 15 Click Complete Setup"
              hrefLink="step-15-click-complete-setup"
            />
            <p className="mb-2">
              Click "Complete Setup" to finish the integration process. A
              confirmation message "Instagram Connected Successfully" will
              appear.
            </p>
            <img
              src={click_complete_setup}
              alt="click_complete_setup"
              className="border"
            />
          </div>

          <div className="mb-8">
            <p className="mb-2">Instagram Connected Successfully</p>
            <img
              src={instagram_successfully}
              alt="instagram_successfully"
              className="border"
            />
          </div>

          <div className="mb-8">
            <SubTitle
              subtitle="Step - 16 Navigate to Application"
              hrefLink="step-16-navigate-to-application"
            />
            <p className="mb-2">
              Go back to the XUnified and select the "Application" section to
              manage app integrations.
            </p>
            <img
              src={navigate_application}
              alt="navigate_application"
              className="border"
            />
          </div>

          <div className="mb-8">
            <SubTitle
              subtitle="Step - 17 Click on Existing Application, it will move to application detail"
              hrefLink="step-17-click-on-existing-application"
            />
            <p className="mb-2">
              Click on "Existing Application" to view details and manage
              existing application settings.
            </p>
            <img
              src={existing_application}
              alt="existing_application"
              className="border"
            />
          </div>

          <div className="mb-8">
            <SubTitle
              subtitle="Step - 18 Select Bot"
              hrefLink="step-18-select-bot"
            />
            <p className="mb-2">
              In the application details, select the Bot option from the Bot
              Connect drop-down.
            </p>
            <img src={select_bot} alt="select_bot" className="border" />
          </div>

          <div className="mb-8">
            <SubTitle
              subtitle="Step - 19 Connect Bot"
              hrefLink="step-19-connect-bot"
            />
            <p className="mb-2">
              Initiate the connection process for your bot with an application
              by selecting the appropriate bot.
            </p>
            <img src={click_connect} alt="click_connect" className="border" />
          </div>

          <div className="mb-8">
            <SubTitle
              subtitle="Step - 20 Connect Instagram Account with Application"
              hrefLink="step-20-connect-instagram-account-with-application"
            />
            <p className="mb-2">
              Connect your Instagram Account to an application by clicking on
              the "Connect" button.
            </p>
            <img
              src={click_connect_insta}
              alt="click_connect_insta"
              className="border"
            />
          </div>

          <div className="mb-8">
            <SubTitle
              subtitle="Step - 21 Send Instagram message on configured Instagran Account"
              hrefLink="step-21-send-instagarm-message-on-configured-instagram-account"
            />
            <p className="mb-2">
              Test the setup by sending a message from the configured instagram
              account.
            </p>
            <img
              width="400px"
              src={send_nstagram_message}
              alt="send_nstagram_message"
              className="border"
            />
          </div>

          <div className="mb-8">
            <SubTitle
              subtitle="Step - 22 Open configured Instagram Account to see the messages"
              hrefLink="step-22-open-configured-instagram-account-messages"
            />
            <p className="mb-2">
              Check your Instagram account to view the message and verify
              successful delivery.
            </p>
            <img
              src={open_insta_account}
              alt="open_insta_account"
              className="border"
            />
          </div>

          <div className="mb-8">
            <SubTitle
              subtitle="Step - 23 Navigate to Live Chat"
              hrefLink="step-23-navigate-to-live-chat"
            />
            <p className="mb-2">
              Navigate to the Live Chat section within your platform XUnified.
            </p>
            <img
              src={navigate_live_chat}
              alt="navigate_live_chat"
              className="border"
            />
          </div>

          <div className="mb-8">
            <SubTitle
              subtitle="Step - 24 Open the Chat"
              hrefLink="step-24-open-the-chat"
            />
            <p className="mb-2">
              Click to open the chat window and start interacting with users or
              customers directly.
            </p>
            <img src={open_chat} alt="open_chat" className="border" />
          </div>

          <div className="flex justify-between">
            <PreviousBtn
              path="/omnichannel/messenger-integration"
              btnText="Messenger Integration"
            />
            <NextBtn
              path="/omnichannel/telegram-integration"
              btnText="Telegram Integration"
            />
          </div>
        </div>

        <RightBar rightBarMenu={rightBarMenu} />
      </div>
    </>
  );
}

export default InstagramIntegration;
