import Application from "./pages/application/Application";
import ApiKey from "./pages/botconfiguration/ApiKey";
import Dialogflow from "./pages/botconfiguration/Dialogflow";
import Fundamentals from "./pages/fundamentals/Fundamentals";
import DocsMain from "./pages/mainpage/DocsMain";
import InstagramIntegration from "./pages/omnichannel/InstagramIntegration";
import MessengerIntegration from "./pages/omnichannel/MessengerIntegration";
import TelegramIntegration from "./pages/omnichannel/TelegramIntegration";
import TwilioIntegration from "./pages/omnichannel/TwilioIntegration";
import WhatsAppIntegration from "./pages/omnichannel/WhatsAppIntegration";
import QuickStart from "./pages/quickstart/QuickStart";
import SignupSignin from "./pages/signupsignin/SignupSignin";
import Settings from "./pages/settings/Settings";
import LLM from "./pages/botconfiguration/LLM";
import Analytics from "./pages/analytics/Analytics";
import Logs from "./pages/logs/Logs";
import Contacts from "./pages/contact/Contacts";

const routeArry = [
  {
    path: "/docs",
    title: "XUnified Docs",
    element: <DocsMain />,
    exact: true,
  },
  {
    path: "/quick-start",
    title: "Quick Start",
    element: <QuickStart />,
    exact: true,
  },
  {
    path: "/signup-signin",
    title: "Sign Up & Sign In",
    element: <SignupSignin />,
    exact: true,
  },
  {
    path: "/fundamentals",
    title: "Fundamentals",
    element: <Fundamentals />,
    exact: true,
  },
  {
    path: "/dashbord",
    title: "Dashbord",
    element: "",
    exact: true,
  },
  {
    path: "/applications",
    title: "Applications",
    element: <Application />,
    exact: true,
  },
  {
    path: "/omnichannel/whatsApp-integration",
    title: "WhatsApp Integration",
    element: <WhatsAppIntegration />,
    exact: true,
  },
  {
    path: "/omnichannel/messenger-integration",
    title: "Messenger Integration",
    element: <MessengerIntegration />,
    exact: true,
  },
  {
    path: "/omnichannel/instagram-integration",
    title: "Instagram Integration",
    element: <InstagramIntegration />,
    exact: true,
  },
  {
    path: "/omnichannel/telegram-integration",
    title: "Telegram Integration",
    element: <TelegramIntegration />,
    exact: true,
  },
  {
    path: "/omnichannel/twilio-integration",
    title: "Twilio Integration",
    element: <TwilioIntegration />,
    exact: true,
  },
  {
    path: "/omnichannel/textMagic-integration",
    title: "TextMagic Integration",
    element: "",
    exact: true,
  },
  {
    path: "/bot-configuration/api-key",
    title: "API Key",
    element: <ApiKey />,
    exact: true,
  },
  {
    path: "/bot-configuration/dialogflow",
    title: "Dialogflow",
    element: <Dialogflow />,
    exact: true,
  },
  {
    path: "/bot-configuration/llm",
    title: "LLM",
    element: <LLM />,
    exact: true,
  },
  {
    path: "/bot-configuration/ai-studio",
    title: "AI Studio",
    element: "",
    exact: true,
  },
  {
    path: "/campaigns",
    title: "Campaigns",
    element: "",
    exact: true,
  },
  {
    path: "/live-chat",
    title: "Live Chat",
    element: "",
    exact: true,
  },
  {
    path: "/analytics",
    title: "Analytics",
    element: <Analytics />,
    exact: true,
  },
  {
    path: "/contacts",
    title: "Contacts",
    element: <Contacts />,
    exact: true,
  },
  {
    path: "/logs",
    title: "Logs",
    element: <Logs />,
    exact: true,
  },
  {
    path: "/settings",
    title: "Settings",
    element: <Settings />,
    exact: true,
  },
  {
    path: "/support",
    title: "Support",
    element: "",
    exact: true,
  },
];

export default routeArry;
