import React from "react";
import Title from "../../components/Title";
import SubTitle from "../../components/SubTitle";
import RightBar from "../../components/RightBar";
import createApplication from "../../images/quickStartImage/createApplication.gif";
import duplicateApplication from "../../images/quickStartImage/duplicateApplication.gif";
import createBot from "../../images/quickStartImage/createBot.gif";
import connectBot from "../../images/quickStartImage/connectBot.gif";
import onnectSocialChannel from "../../images/quickStartImage/connectSocialChannel.gif";
import Topbar from "../../components/Topbar";
import ScrollActive from "../../components/ScrollActive";
import PreviousBtn from "../../components/PreviousBtn";
import NextBtn from "../../components/NextBtn";

const rightBarMenu = [
  {
    tolink: "step-1-create-an-application",
    submenutext: "Step - 1 Create an application",
    children: [
      {
        tolink: "create-an-application-from-scratch",
        submenutext: "Create an Application from Scratch",
      },
      {
        tolink: "create-an-application-from-existing-application",
        submenutext: "Create an Application from Existing Application",
      },
      {
        tolink: "whats-next1",
        submenutext: "What’s Next?",
      },
    ],
  },
  {
    tolink: "step-2-create-a-bot",
    submenutext: "Step - 2 Create a Bot",
    children: [
      {
        tolink: "whats-next2",
        submenutext: "What’s Next?",
      },
    ],
  },
  {
    tolink: "step-3-connect-a-bot",
    submenutext: "Step - 3 Connect a Bot",
    children: [
      {
        tolink: "whats-next3",
        submenutext: "What’s Next?",
      },
    ],
  },
  {
    tolink: "step-4-connect-social-channel-or-web",
    submenutext: "Step - 4 Connect Social Channel or Web",
  },
];

function QuickStart() {
  return (
    <>
      <ScrollActive rightBarMenu={rightBarMenu} />
      <Topbar rightBarMenu={rightBarMenu} />

      <div className="lg:flex gap-6 sm:p-8 p-5 text-gray-600">
        <div className="flex-1">
          <Title title="Quick Start" />
          <SubTitle
            subtitle="Step - 1 Create an application"
            hrefLink="step-1-create-an-application"
          />
          <p className="mb-2">
            After completing the sign-up process for XUnified, you're all set to
            begin crafting an Application on XUnified. There are two ways to
            create a new Application on XUnified:
          </p>
          <ul className="mb-5 pl-5 space-y-2">
            <li className="list-decimal">Create an Application from Scratch</li>
            <li className="list-decimal">
              Create an Application from Existing Application
            </li>
          </ul>
          <p className="mb-5">
            An application is a set of security and configuration information
            for connecting external accounts, numbers, and webhooks to the
            XUnified API.
          </p>

          <SubTitle
            subtitle="Create an Application from Scratch"
            hrefLink="create-an-application-from-scratch"
          />
          <p className="mb-5">
            In XUnified, an application is a bundle designed to execute a
            particular task either for an end user directly or, in certain
            instances, for another application.
          </p>
          <div className="mb-8">
            <p className="mb-2 text-lg text-black font-medium">
              Step - 1 Navigate to Application.
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              Step - 2 In your workspace click on the Create Application button
              on the top right.
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              Step - 3 When creating a new application, you will see “Create an
              application” pop-up.
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              Step - 4 Enter Application name and click on Create.
            </p>
            <img
              src={createApplication}
              alt="createApplication"
              className="border"
            />
          </div>

          <SubTitle
            subtitle="Create an Application from Existing Application"
            hrefLink="create-an-application-from-existing-application"
          />
          <p className="mb-5">
            In XUnified, user can create an application from an existing
            application.
          </p>
          <div className="mb-8">
            <p className="mb-2 text-lg text-black font-medium">
              Step - 1 Navigate to Application.
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              Step - 2 In your application workspace, you will see an existing
              application.
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              Step - 3 Click on Three dots, and click on Duplicate option of an
              existing application.
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              Step - 4 It will open Duplicate an application pop-up.
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              Step - 5 Change Application name if you wish. Click on Duplicate.
            </p>
            <img
              src={duplicateApplication}
              alt="duplicateApplication"
              className="border"
            />
          </div>

          <SubTitle subtitle="What’s Next?" hrefLink="whats-next1" />
          <ul className="mb-5 pl-5 space-y-2">
            <li className="list-disc">
              Create a Bot: Using our Dialogflow, LLM, and AI Studio feature you
              can create bot and power your bot instantly.
            </li>
            <li className="list-disc">
              Connect a Bot: Connect your bot in the application.
            </li>
            <li className="list-disc">
              Connect Omnichannel or Web: Connect to Omni channel or website.
            </li>
          </ul>

          <SubTitle
            subtitle="Step - 2 Create a Bot"
            hrefLink="step-2-create-a-bot"
          />
          <p className="mb-5">
            Utilizing XUnified's sophisticated Bot Configuration feature enables
            enterprises to streamline operations, boost customer self-service
            functionalities, and deliver uninterrupted support to their
            business. This encompasses defining responses, comprehending user
            inputs, and configuring the bot's actions in response to particular
            triggers or scenarios.
          </p>
          <div className="mb-8">
            <p className="mb-2 text-lg text-black font-medium">
              Step - 1 Navigate to Bot Configuration.
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              Step - 2 Click on Create Bot, on the top right corner.
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              As you click on create bot, you will find three options:
              Dialogflow, LLM, and AI Studio. Select as per your requirement.
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              Step - 3 Click LLM.
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              It will open LLM workspace.
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              Step - 4 Click on Create LLM.
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              Step - 5 Enter bot name and Select AI Model from the drop-down.
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              Step - 6 Select model type either Knowledgebase or Prompt, Select
              Secret Key, and Click Next.
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              Step - 7 Select Source Type, enter Value, Save Source, and click
              Continue.
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              Step - 8 Review and click Train Model.
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              It will create the model and reflect in LLM workspace.
            </p>
            <img src={createBot} alt="createBot" className="border" />
          </div>

          <SubTitle subtitle="What’s Next?" hrefLink="whats-next2" />
          <ul className="mb-5 pl-5 space-y-2">
            <li className="list-disc">
              Connect a Bot: Connect your bot in the application.
            </li>
            <li className="list-disc">
              Connect Omnichannel or Web: Connect to Omni channel or website.
            </li>
          </ul>

          <SubTitle
            subtitle="Step - 3 Connect a Bot"
            hrefLink="step-3-connect-a-bot"
          />
          <p className="mb-5">
            Now that you’ve added information to your Bot, it’s time to connect
            a Bot with application.
          </p>
          <div className="mb-8">
            <p className="mb-2 text-lg text-black font-medium">
              Step - 1 Navigate to Application.
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              Step - 2 In your application workspace, you will notice an
              existing application.
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              Step - 3 Click on an existing application, you will move to the
              detail of the application.
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              Step - 4 Select the Bot from the Bot Connect drop-down.
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              Step - 5 Click on connect.
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              Bot connected successfully.
            </p>
            <img src={connectBot} alt="connectBot" className="border" />
          </div>

          <SubTitle subtitle="What’s Next?" hrefLink="whats-next3" />
          <ul className="mb-5 pl-5 space-y-2">
            <li className="list-disc">
              Connect Omnichannel or Web: Connect to Omni channel or website.
            </li>
          </ul>

          <SubTitle
            subtitle="Step - 4 Connect Social Channel or Web"
            hrefLink="step-4-connect-social-channel-or-web"
          />
          <div className="mb-8">
            <p className="mb-5">
              After successfully linking the Bot to your Application, it's time
              to establish connections with social channels or websites.
            </p>
            <p className="mb-5">
              Connect Social Channel, Web Widget, or Web Chat
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              Step - 1 Navigate to Application.
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              Step - 2 In your application workspace, you will notice an
              existing application.
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              Step - 3 Click on an existing application, you will move to the
              detail of the application.
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              Step - 4 You will notice configured social channel in lower part.
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              Step - 5 Click connect desired social channel.
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              Channel connected successfully.
            </p>
            <img
              src={onnectSocialChannel}
              alt="onnectSocialChannel"
              className="border"
            />
          </div>

          <div className="flex justify-between">
            <PreviousBtn path="/docs" btnText="XUnified Docs" />
            <NextBtn path="/signup-signin" btnText="Sign Up & Sign In" />
          </div>
        </div>
        <RightBar rightBarMenu={rightBarMenu} />
      </div>
    </>
  );
}

export default QuickStart;
