import React from "react";
import {
  IconApps,
  IconBinaryTree2,
  IconBolt,
  IconBook,
  IconChartBar,
  IconDashboard,
  IconDoor,
  IconHelp,
  IconListDetails,
  IconMessageChatbot,
  IconRobotFace,
  IconSettings,
  IconSpeakerphone,
  IconUserCircle,
} from "@tabler/icons-react";
import { Link } from "react-router-dom";
import NextBtn from "../../components/NextBtn";

const started = [
  {
    icon: <IconBook />,
    title: "Quick Start",
    path: "/quick-start",
    titleContent:
      "After completing the sign-up process for XUnified, you're all set to begin crafting an Application on XUnified",
  },
  {
    icon: <IconDoor />,
    title: "Sign Up & Sign In",
    path: "/signup-signin",
    titleContent:
      "Unlock the full potential of your team with XUnified. Signing up is simple and quick. Choose one of the following methods to get started",
  },
  {
    icon: <IconBolt />,
    title: "Fundamentals",
    path: "/Fundamentals",
    titleContent:
      "XUnified, your comprehensive solution for customer engagement and communication needs With XUnified, you can unlock your growth opportunities and enhance",
  },
];

const features = [
  {
    icon: <IconDashboard />,
    title: "Dashboard",
    path: "/dashboard",
    titleContent:
      "We prioritize quality in every aspect of our product and service delivery. XUnified is engineered to deliver top-notch performance, ",
  },
  {
    icon: <IconApps />,
    title: "Applications",
    path: "/applications",
    titleContent:
      "Welcome to our integrated Create Application feature, designed to empower you to effortlessly build and customize your customer engagement channels.",
  },
  {
    icon: <IconBinaryTree2 />,
    title: "Omnichannel",
    path: "/omnichannel",
    titleContent:
      "Our omni channel customer engagement platform seamlessly integrates various communication platforms, providing a cohesive and streamlined customer experience.",
  },
  {
    icon: <IconRobotFace />,
    title: "Bot Configuration",
    path: "/bot-configuration",
    titleContent:
      "AI bots revolutionize customer interaction, offering personalized assistance and seamless engagement, driving efficiency and customer satisfaction.",
  },
  {
    icon: <IconSpeakerphone />,
    title: "Campaigns",
    path: "/campaigns",
    titleContent:
      "Drive engagement and conversions with targeted and personalized marketing campaigns tailored to your audience's preferences and behaviours.",
  },
  {
    icon: <IconMessageChatbot />,
    title: "Live Chat",
    path: "/live-chat",
    titleContent:
      "Live chat functionality revolutionizes real-time communication, offering seamless customer and business interaction and engagement.",
  },
];

const integrations = [
  {
    icon: <IconChartBar />,
    title: "Analytics",
    path: "/analytics",
    titleContent:
      "Welcome to the Analytics Dashboard, where you can gain valuable insights into your platform's performance. This section provides detailed analysis across various aspects",
  },
  {
    icon: <IconUserCircle />,
    title: "Contacts",
    path: "/contacts",
    titleContent:
      "The Contact menu in XUnified allows you to efficiently manage all your contacts. From this section, you can",
  },
  {
    icon: <IconListDetails />,
    title: "Logs",
    path: "/logs",
    titleContent:
      "The Logs section is a comprehensive tool for monitoring and reviewing all activities related to your system. It provides detailed logs for various components, including",
  },
  {
    icon: <IconSettings />,
    title: "Settings",
    path: "/settings",
    titleContent:
      "Welcome to the XUnified Settings! Here, you can manage all essential configurations to tailor your experience. Update your personal information under",
  },
  {
    icon: <IconHelp />,
    title: "Support",
    path: "/support",
    titleContent:
      "The Support section is your go-to resource for assistance and issue resolution. If you encounter any functionality problems or require help",
  },
];

function DocsMain() {
  return (
    <div className="sm:p-8 p-5">
      <div className="mb-6">
        <h1 className="font-semibold text-[30px] text-gray-800 mb-4 leading-9">
          XUnified Docs
        </h1>
        <p className="text-gray-600 font-light">
          Manage your subscription and payment details
        </p>
      </div>
      <div className="grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-6 mb-12">
        {started.map((item, index) => (
          <Link
            key={index}
            to={item.path}
            className="rounded-2xl border border-gray-200 hover:border-gray-400 p-6 hover:bg-gray-50"
          >
            <div className="rounded-[10px] border border-gray-200 p-2 mb-4 inline-block text-gray-500 bg-white">
              {item.icon}
            </div>
            <p className="text-gray-800 text-xl font-medium mb-2">
              {item.title}
            </p>
            <p className="text-gray-500 font-light line-clamp-3">
              {item.titleContent}
            </p>
          </Link>
        ))}
      </div>
      <div className="mb-12">
        <p className="mb-6 font-semibold text-2xl text-gray-800">
          Build & Manage
        </p>
        <div className="grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-6">
          {features.map((item, index) => (
            <Link
              key={index}
              to={item.path}
              className="rounded-2xl border border-gray-200 hover:border-gray-400 p-6 hover:bg-gray-50"
            >
              <div className="rounded-[10px] border border-gray-200 p-2 mb-4 inline-block text-gray-500 bg-white">
                {item.icon}
              </div>
              <p className="text-gray-800 text-xl font-medium mb-2">
                {item.title}
              </p>
              <p className="text-gray-500 font-light line-clamp-3">
                {item.titleContent}
              </p>
            </Link>
          ))}
        </div>
      </div>
      <div className="mb-12">
        <p className="mb-6 font-semibold text-2xl text-gray-800">Quick Link</p>
        <div className="grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-6">
          {integrations.map((item, index) => (
            <Link
              key={index}
              to={item.path}
              className="rounded-2xl border border-gray-200 hover:border-gray-400 p-6 hover:bg-gray-50"
            >
              <div className="rounded-[10px] border border-gray-200 p-2 mb-4 inline-block text-gray-500 bg-white">
                {item.icon}
              </div>
              <p className="text-gray-800 text-xl font-medium mb-2">
                {item.title}
              </p>
              <p className="text-gray-500 font-light line-clamp-3">
                {item.titleContent}
              </p>
            </Link>
          ))}
        </div>
      </div>
      <div className="flex justify-end">
        <NextBtn path="/quick-start" btnText="Quick Start" />
      </div>
    </div>
  );
}

export default DocsMain;
