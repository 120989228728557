import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showToogleMenu: false,
  selectRightMenu: false,
  linkURL: "",
  scrollLinkURL: "",
  scrollLinkText: "",
};

export const MenuSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    toogleMenu: (state) => {
      state.showToogleMenu = true;
      document.body.style.overflow = "hidden";
      document.body.style.paddingRight = "6px";
    },
    toogleMenuOff: (state) => {
      state.showToogleMenu = false;
      document.body.style.overflow = "";
      document.body.style.paddingRight = "";
    },
    activemenu: (state, actions) => {
      state.linkURL = actions.payload;
      state.selectRightMenu = true;
    },
    scrollActivemenu: (state, actions) => {
      state.scrollLinkURL = actions.payload;
    },
    scrollActivemenuText: (state, actions) => {
      state.scrollLinkText = actions.payload;
    },
  },
});

export const {
  toogleMenu,
  toogleMenuOff,
  activemenu,
  scrollActivemenu,
  scrollActivemenuText,
} = MenuSlice.actions;

export default MenuSlice.reducer;
