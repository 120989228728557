import React from "react";
import Title from "../../components/Title";
import SubTitle from "../../components/SubTitle";
import RightBar from "../../components/RightBar";
import Topbar from "../../components/Topbar";
import ScrollActive from "../../components/ScrollActive";
import signin from "../../images/signupsignin/signin.gif";
import signup from "../../images/signupsignin/signup.gif";
import PreviousBtn from "../../components/PreviousBtn";
import NextBtn from "../../components/NextBtn";

const rightBarMenu = [
  {
    tolink: "sign-up",
    submenutext: "Sign Up",
  },
  {
    tolink: "sign-in",
    submenutext: "Sign In",
  },
];

function SignupSignin() {
  return (
    <>
      <ScrollActive rightBarMenu={rightBarMenu} />
      <Topbar rightBarMenu={rightBarMenu} />

      <div className="lg:flex gap-6 sm:p-8 p-5 text-gray-600">
        <div className="flex-1">
          <Title title="Sign Up & Sign In" />
          <SubTitle subtitle="Sign Up" hrefLink="sign-up" />
          <p className="mb-5">Welcome to XUnified!</p>
          <p className="mb-2">
            Unlock the full potential of your team with XUnified. Signing up is
            simple and quick. Choose one of the following methods to get
            started:
          </p>
          <ul className="mb-5 pl-5 space-y-2">
            <li className="list-decimal">
              Google: Use your Google account for a seamless sign-up experience
            </li>
            <li className="list-decimal">
              Apple: Sign up securely with your Apple ID
            </li>
            <li className="list-decimal">
              Email ID: Prefer using your email? No problem! Sign up with your
              email address
            </li>
          </ul>
          <p className="mb-2">
            Once you've selected your preferred sign-up method, you'll be guided
            through the following steps:
          </p>
          <ul className="mb-5 pl-5 space-y-2">
            <li className="list-decimal">
              Personal Details: Tell us a little about yourself to create your
              profile
            </li>
            <li className="list-decimal">
              Company Details: Provide information about your company to tailor
              your experience
            </li>
            <li className="list-decimal">
              Team Size: Let us know the size of your team to ensure we cater to
              your needs
            </li>
          </ul>
          <div className="mb-8">
            <p className="mb-2 text-lg text-black font-medium">
              Step - 1 Click on Get Started for Free
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              It will open the Sign up to XUnified page.
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              User can Sign up with Google, Apple or Email ID
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              Step - 2 Enter Email, Password, and click on Sign up button
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              Step - 3 It will open Verify your email page, enter 6-digit code
              from the entered email id and click on Verify Email button.
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              Step - 4 It will open Enter your details page, enter First Name,
              Last Name, Mobile Number and click on Continue button.
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              Step - 5 It will open Enter your company details page, enter
              Company Name, Website, select Industry Type from drop-down, and
              click on Continue button.
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              Step - 6 It will open What is your team size? page, select size,
              and click on Let’s Get Started button.
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              It will redirect you on the dashboard
            </p>
            <img src={signup} alt="signup" className="border" />
          </div>

          <SubTitle subtitle="Sign In" hrefLink="sign-in" />
          <div className="mb-8">
            <p className="mb-2 text-lg text-black font-medium">
              Step - 1 Click on Sign in.
            </p>
            <p className="mb-5">
              It will open the Sign in to XUnified page. User can Sign in with
              Google, Apple or Email.
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              Step - 2 Enter Email, Password, and click on Sign in button.
            </p>
            <p className="mb-5">It will redirect you on the dashboard</p>
            <img src={signin} alt="signin" className="border" />
          </div>

          <div className="flex justify-between">
            <PreviousBtn path="/quick-start" btnText="Quick Start" />
            <NextBtn path="/fundamentals" btnText="Fundamentals" />
          </div>
        </div>

        <RightBar rightBarMenu={rightBarMenu} />
      </div>
    </>
  );
}

export default SignupSignin;
