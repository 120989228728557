import React from "react";
import ScrollActive from "../../components/ScrollActive";
import Topbar from "../../components/Topbar";
import Title from "../../components/Title";
import SubTitle from "../../components/SubTitle";
import PreviousBtn from "../../components/PreviousBtn";
import NextBtn from "../../components/NextBtn";
import RightBar from "../../components/RightBar";
import navigate_OmniChannel from "../../images/omnichannelimg/telegramintegrationimg/navigate_OmniChannel.jpg";
import navigate_Telegram from "../../images/omnichannelimg/telegramintegrationimg/navigate_Telegram.jpg";
import click_Connect from "../../images/omnichannelimg/telegramintegrationimg/click_Connect.jpg";
import botFather from "../../images/omnichannelimg/telegramintegrationimg/botFather.jpg";
import click_START from "../../images/omnichannelimg/telegramintegrationimg/click_START.jpg";
import create_new_bot from "../../images/omnichannelimg/telegramintegrationimg/create_new_bot.jpg";
import copy_Token from "../../images/omnichannelimg/telegramintegrationimg/copy_Token.jpg";
import choose_your_bot from "../../images/omnichannelimg/telegramintegrationimg/choose_your_bot.jpg";
import enter_copied_token from "../../images/omnichannelimg/telegramintegrationimg/enter_copied_token.jpg";
import click_Connect11 from "../../images/omnichannelimg/telegramintegrationimg/click_Connect11.jpg";
import telegram_Connected_suc from "../../images/omnichannelimg/telegramintegrationimg/telegram_Connected_suc.jpg";
import navigate_application from "../../images/omnichannelimg/whatsAppintegrationimg/navigate_application.jpg";
import click_existing_application from "../../images/omnichannelimg/whatsAppintegrationimg/click_existing_application.jpg";
import select_Bot from "../../images/omnichannelimg/telegramintegrationimg/select_Bot.jpg";
import connect_Bot from "../../images/omnichannelimg/telegramintegrationimg/connect_Bot.jpg";
import connect_telegram from "../../images/omnichannelimg/telegramintegrationimg/connect_telegram.jpg";
import search_Bot from "../../images/omnichannelimg/telegramintegrationimg/search_Bot.jpg";
import navigate_live_chat from "../../images/omnichannelimg/telegramintegrationimg/navigate_live_chat.jpg";
import open_Chat from "../../images/omnichannelimg/telegramintegrationimg/open_Chat.jpg";

const rightBarMenu = [
  {
    tolink: "step-1-navigate-to-omni-channel",
    submenutext: "Step - 1 Navigate to Omni Channel",
  },
  {
    tolink: "step-2-navigate-to-telegram",
    submenutext: "Step - 2 Navigate to Telegram",
  },
  {
    tolink: "step-3-click-connect",
    submenutext: "Step - 3 Click Connect",
  },
  {
    tolink: "step-4-open-telegram-press-enter",
    submenutext: "Step - 4 Open Telegram, search for @BotFather",
  },
  {
    tolink: "step-5-click-on-start-it-will-run-start-command",
    submenutext: "Step - 5 Click on START",
  },
  {
    tolink: "step-6-for-create-a-new-bot",
    submenutext: "Step - 6 For create a new bot",
  },
  {
    tolink: "step-7-asking-for-choose-a-name-for-your-bot",
    submenutext: "Step - 7 Asking for Choose a name for your bot",
  },
  {
    tolink: "step-8-asking-for-choose-username-for-your-bot",
    submenutext: "Step - 8 Asking for Choose Username for your bot",
  },
  {
    tolink: "step-9-copy-the-token",
    submenutext: "Step - 9 Copy the token",
  },
  {
    tolink: "step-10-back-to-xunified-enter-the-copied-token",
    submenutext: "Step - 10 Back to XUnified, Enter the copied Token",
  },
  {
    tolink: "step-11-click-connect",
    submenutext: "Step - 11 Click Connect",
  },
  {
    tolink: "step-12-navigate-to-application",
    submenutext: "Step - 12 Navigate to Application",
  },
  {
    tolink: "step-13-click-on-existing-application",
    submenutext: "Step - 13 Click on Existing Application",
  },
  {
    tolink: "step-14-select-bot",
    submenutext: "Step - 14 Select Bot",
  },
  {
    tolink: "step-15-connect-bot",
    submenutext: "Step - 15 Connect Bot",
  },
  {
    tolink: "step-16-connect-telegram-bot",
    submenutext: "Step - 16 Connect Telegram Bot",
  },
  {
    tolink: "step-17-search-and-send-message-from-telegram",
    submenutext: "Step - 17 Search and send message from Telegram",
  },
  {
    tolink: "step-18-navigate-to-live-chat-in-xunified",
    submenutext: "Step - 18 Navigate to Live Chat in XUnified",
  },
  {
    tolink: "step-19-open-the-chat",
    submenutext: "Step - 19 Open the Chat",
  },
];

function TelegramIntegration() {
  return (
    <>
      <ScrollActive rightBarMenu={rightBarMenu} />
      <Topbar rightBarMenu={rightBarMenu} />

      <div className="lg:flex gap-6 sm:p-8 p-5 text-gray-600">
        <div className="flex-1">
          <Title title="Telegram Integration" />

          <div className="mb-8">
            <SubTitle
              subtitle="Step - 1 Navigate to Omni Channel"
              hrefLink="step-1-navigate-to-omni-channel"
            />
            <p className="mb-2">
              Begin by accessing the Omni Channel feature within your platform
              XUnified.
            </p>
            <img
              src={navigate_OmniChannel}
              alt="navigate_OmniChannel"
              className="border"
            />
          </div>

          <div className="mb-8">
            <SubTitle
              subtitle="Step - 2 Navigate to Telegram"
              hrefLink="step-2-navigate-to-telegram"
            />
            <p className="mb-2">
              Locate and select the Telegram option within the Omni Channel.
            </p>
            <img
              src={navigate_Telegram}
              alt="navigate_Telegram"
              className="border"
            />
          </div>

          <div className="mb-8">
            <SubTitle
              subtitle="Step - 3 Click Connect"
              hrefLink="step-3-click-connect"
            />
            <p className="mb-2">
              Initiate the connection process by clicking on the "Connect"
              button.
            </p>
            <img src={click_Connect} alt="click_Connect" className="border" />
          </div>

          <div className="mb-8">
            <SubTitle
              subtitle="Step - 4 Open Telegram, search for @BotFather, and Press Enter"
              hrefLink="step-4-open-telegram-press-enter"
            />
            <p className="mb-2">
              Launch the Telegram application and search for the @BotFather bot.
              Once found, press Enter to proceed.
            </p>
            <img src={botFather} alt="botFather" className="border" />
          </div>

          <div className="mb-8">
            <SubTitle
              subtitle="Step - 5 Click on START, it will run /start command"
              hrefLink="step-5-click-on-start-it-will-run-start-command"
            />
            <p className="mb-2">
              Start the interaction with @BotFather by clicking on the "START"
              button, triggering the /start command.
            </p>
            <img src={click_START} alt="click_START" className="border" />
          </div>

          <div className="mb-8">
            <SubTitle
              subtitle="Step - 6 For create a new bot type /newbot, and Press Enter"
              hrefLink="step-6-for-create-a-new-bot"
            />
            <p className="mb-2">
              To create a new bot, type /newbot into the chat with @BotFather
              and press Enter.
            </p>
            <img src={create_new_bot} alt="create_new_bot" className="border" />
          </div>

          <div className="mb-8">
            <SubTitle
              subtitle="Step - 7 Asking for Choose a name for your bot"
              hrefLink="step-7-asking-for-choose-a-name-for-your-bot"
            />
            <p className="mb-2">
              @BotFather will prompt you to choose a name for your new bot.
              Enter the desired name when prompted.
            </p>
            <img src={create_new_bot} alt="create_new_bot" className="border" />
          </div>

          <div className="mb-8">
            <SubTitle
              subtitle="Step - 8 Asking for Choose Username for your bot"
              hrefLink="step-8-asking-for-choose-username-for-your-bot"
            />
            <p className="mb-2">
              After providing a name, @BotFather will ask you to choose a
              username for your bot. Enter the desired username when prompted.
              Username must end in ‘bot’.
            </p>
            <img
              src={choose_your_bot}
              alt="choose_your_bot"
              className="border"
            />
          </div>

          <div className="mb-8">
            <SubTitle
              subtitle="Step - 9 Copy the token"
              hrefLink="step-9-copy-the-token"
            />
            <p className="mb-2">
              Upon successful creation, @BotFather will provide you with a
              unique token for your bot. Copy this token for later use.
            </p>
            <img src={copy_Token} alt="copy_Token" className="border" />
          </div>

          <div className="mb-8">
            <SubTitle
              subtitle="Step - 10 Back to XUnified, Enter the copied Token"
              hrefLink="step-10-back-to-xunified-enter-the-copied-token"
            />
            <p className="mb-2">
              Return to the XUnified platform and paste the copied token into
              the Set up Telegram with XUnified pop-up.
            </p>
            <img
              src={enter_copied_token}
              alt="enter_copied_token"
              className="border"
            />
          </div>

          <div className="mb-8">
            <SubTitle
              subtitle="Step - 11 Click Connect"
              hrefLink="step-11-click-connect"
            />
            <p className="mb-2">
              Complete the connection process by clicking on "Connect,"
              confirming that the Telegram integration was successful.
            </p>
            <img
              src={click_Connect11}
              alt="click_Connect11"
              className="border"
            />
          </div>

          <div className="mb-8">
            <p className="mb-2">Telegram Connected Successfully</p>
            <img
              src={telegram_Connected_suc}
              alt="telegram_Connected_suc"
              className="border"
            />
          </div>

          <div className="mb-8">
            <SubTitle
              subtitle="Step - 12 Navigate to Application"
              hrefLink="step-12-navigate-to-application"
            />
            <p className="mb-2">
              Proceed to the Application section within your platform XUnified.
            </p>
            <img
              src={navigate_application}
              alt="navigate_application"
              className="border"
            />
          </div>

          <div className="mb-8">
            <SubTitle
              subtitle="Step - 13 Click on Existing Application, it will move to application detail"
              hrefLink="step-13-click-on-existing-application"
            />
            <p className="mb-2">
              Click an existing application, which will redirect you to the
              application details page.
            </p>
            <img
              src={click_existing_application}
              alt="click_existing_application"
              className="border"
            />
          </div>

          <div className="mb-8">
            <SubTitle
              subtitle="Step - 14 Select Bot"
              hrefLink="step-14-select-bot"
            />
            <p className="mb-2">
              In the application details, select the Bot option from the Bot
              Connect drop-down.
            </p>
            <img src={select_Bot} alt="select_Bot" className="border" />
          </div>

          <div className="mb-8">
            <SubTitle
              subtitle="Step - 15 Connect Bot"
              hrefLink="step-15-connect-bot"
            />
            <p className="mb-2">
              Initiate the connection process for your bot with an application
              by selecting the appropriate bot.
            </p>
            <img src={connect_Bot} alt="connect_Bot" className="border" />
          </div>

          <div className="mb-8">
            <SubTitle
              subtitle="Step - 16 Connect Telegram Bot"
              hrefLink="step-16-connect-telegram-bot"
            />
            <p className="mb-2">
              Connect your Telegram bot to an application by clicking on the
              "Connect" button.
            </p>
            <img
              src={connect_telegram}
              alt="connect_telegram"
              className="border"
            />
          </div>

          <div className="mb-8">
            <SubTitle
              subtitle="Step - 17 Search for created bot, and send message from Telegram"
              hrefLink="step-17-search-and-send-message-from-telegram"
            />
            <p className="mb-2">
              Test the integration by searching for your created bot within
              Telegram and sending a message to verify functionality.
            </p>
            <img src={search_Bot} alt="search_Bot" className="border" />
          </div>

          <div className="mb-8">
            <SubTitle
              subtitle="Step - 18 Navigate to Live Chat in XUnified"
              hrefLink="step-18-navigate-to-live-chat-in-xunified"
            />
            <p className="mb-2">
              Navigate to the Live Chat section within the XUnified platform.
            </p>
            <img
              src={navigate_live_chat}
              alt="navigate_live_chat"
              className="border"
            />
          </div>

          <div className="mb-8">
            <SubTitle
              subtitle="Step - 19 Open the Chat"
              hrefLink="step-19-open-the-chat"
            />
            <p className="mb-2">
              Open a live chat to begin engaging with users through the
              integrated Telegram channel.
            </p>
            <img src={open_Chat} alt="open_Chat" className="border" />
          </div>

          <div className="flex justify-between">
            <PreviousBtn
              path="/omnichannel/instagram-integration"
              btnText="Instagram Integration"
            />
            <NextBtn
              path="/omnichannel/twilio-integration"
              btnText="Twilio Integration"
            />
          </div>
        </div>

        <RightBar rightBarMenu={rightBarMenu} />
      </div>
    </>
  );
}

export default TelegramIntegration;
