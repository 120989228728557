import React from "react";
import SubTitle from "../../components/SubTitle";
import ScrollActive from "../../components/ScrollActive";
import Topbar from "../../components/Topbar";
import Title from "../../components/Title";
import RightBar from "../../components/RightBar";
import PreviousBtn from "../../components/PreviousBtn";
import NextBtn from "../../components/NextBtn";
import createApplication from "../../images/quickStartImage/createApplication.gif";
import duplicateApplication from "../../images/quickStartImage/duplicateApplication.gif";
import editApplication from "../../images/application/editApplication.gif";
import deleteApplication from "../../images/application/deleteApplication.gif";
import connectBot from "../../images/quickStartImage/connectBot.gif";
import disConnectBot from "../../images/application/disConnectBot.gif";
import configureWidget from "../../images/application/configureWidget.gif";
import connectSocialChannel from "../../images/quickStartImage/connectSocialChannel.gif";
import disconnectSocialChannel from "../../images/application/disconnectSocialChannel.gif";
import configure_Widget1 from "../../images/application/configure_Widget1.jpg";
import configure_Widget2 from "../../images/application/configure_Widget2.jpg";
import configure_Widget3 from "../../images/application/configure_Widget3.jpg";
import configure_Widget4 from "../../images/application/configure_Widget4.jpg";
import configure_Widget5 from "../../images/application/configure_Widget5.jpg";
import configure_Widget6 from "../../images/application/configure_Widget6.jpg";

const rightBarMenu = [
  {
    tolink: "create-an-application",
    submenutext: "Create an application",
  },
  {
    tolink: "edit-an-application",
    submenutext: "Edit an application",
  },
  {
    tolink: "duplicate-an-application",
    submenutext: "Duplicate an application",
  },
  {
    tolink: "delete-an-application",
    submenutext: "Delete an application",
  },
  {
    tolink: "connect-bot-with-an-application",
    submenutext: "Connect Bot with an application",
  },
  {
    tolink: "disconnect-bot-from-an-application",
    submenutext: "Disconnect Bot from an application",
  },
  {
    tolink: "connect-omnichannel-with-an-application",
    submenutext: "Connect omnichannel with an application",
  },
  {
    tolink: "disconnect-omnichannel-from-an-application",
    submenutext: "Disconnect omnichannel from an application",
  },
  {
    tolink: "connect-web",
    submenutext: "Connect Web",
  },
  {
    tolink: "configure-widget",
    submenutext: "Configure Widget",
  },
];

function Application() {
  return (
    <>
      <ScrollActive rightBarMenu={rightBarMenu} />
      <Topbar rightBarMenu={rightBarMenu} />

      <div className="lg:flex gap-6 sm:p-8 p-5 text-gray-600">
        <div className="flex-1">
          <Title title="Application" />

          <p className="mb-5">
            Welcome to our integrated Create Application feature, designed to
            empower you to effortlessly build and customize your customer
            engagement channels. With our user-friendly interfaces and flexible
            design options, you can easily tailor your channels to align with
            your specific business needs and brand identity.
          </p>
          <ul className="mb-5 pl-5 space-y-2">
            <li className="list-disc">
              <span className="text-black font-medium">
                Effortless Customization:
              </span>{" "}
              Create and customize your customer engagement channels with ease,
              ensuring they reflect your unique brand identity and business
              requirements.
            </li>
            <li className="list-disc">
              <span className="text-black font-medium">
                User-Friendly Interfaces:
              </span>{" "}
              Navigate through our intuitive interfaces to build and manage your
              channels without any technical expertise.
            </li>
            <li className="list-disc">
              <span className="text-black font-medium">
                Flexible Design Options:
              </span>{" "}
              Choose from a variety of design options to create a seamless and
              engaging customer experience.
            </li>
            <li className="list-disc">
              <span className="text-black font-medium">
                Omnichannel Connectivity:
              </span>{" "}
              Integrate your application with multiple channels, including web
              and mobile platforms, to provide a consistent and unified customer
              experience.
            </li>
            <li className="list-disc">
              <span className="text-black font-medium">Bot Integration:</span>{" "}
              Connect or disconnect the bot from your application as needed,
              giving you control over automated customer interactions.
            </li>
            <li className="list-disc">
              <span className="text-black font-medium">Web Integration:</span>{" "}
              Seamlessly connect your application with your website to enhance
              customer engagement and support.
            </li>
            <li className="list-disc">
              <span className="text-black font-medium">
                Configurable Widgets:
              </span>{" "}
              Add and configure widgets to your application, allowing for
              enhanced functionality and a richer user experience.
            </li>
          </ul>
          <p className="mb-5">
            With our integrated Create Application feature, you have the tools
            you need to build robust customer engagement channels that drive
            satisfaction and loyalty. Start creating today and transform the way
            you interact with your customers!{" "}
          </p>

          <SubTitle
            subtitle="Create an application"
            hrefLink="create-an-application"
          />
          <div className="mb-8">
            <p className="mb-2 text-lg text-black font-medium">
              Step - 1 Navigate to Application
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              Step - 2 In your workspace click on the Create Application button
              on the top right.
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              Step - 3 When creating a new application, you will notice Create
              an application pop-up.
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              Step - 4 Name your application.
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              Step - 5 Click on Create button.
            </p>
            <img
              src={createApplication}
              alt="createApplication"
              className="border"
            />
          </div>

          <SubTitle
            subtitle="Edit an application"
            hrefLink="edit-an-application"
          />
          <div className="mb-8">
            <p className="mb-2 text-lg text-black font-medium">
              Step - 1 Navigate to Application
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              Step - 2 In your workspace, you will notice an existing
              application.
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              Step - 3 Click Three dots, and click on Edit option of an existing
              application.
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              Step - 4 It will open update an application pop-up.
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              Step - 5 Change the application name and click on update.
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              Application updated successfully.
            </p>
            <img
              src={editApplication}
              alt="editApplication"
              className="border"
            />
          </div>

          <SubTitle
            subtitle="Duplicate an application"
            hrefLink="duplicate-an-application"
          />
          <div className="mb-8">
            <p className="mb-2 text-lg text-black font-medium">
              Step - 1 Navigate to Application
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              Step - 2 In your workspace, you will notice an existing
              application.
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              Step - 3 Click Three dots, and click on Duplicate of an existing
              application.
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              Step - 4 It will open Duplicate an application pop-up.
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              Step - 5 Change the Application name if you wish. Click on
              Duplicate.
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              Application created successfully.
            </p>
            <img
              src={duplicateApplication}
              alt="duplicateApplication"
              className="border"
            />
          </div>

          <SubTitle
            subtitle="Delete an application"
            hrefLink="delete-an-application"
          />
          <div className="mb-8">
            <p className="mb-2 text-lg text-black font-medium">
              Step - 1 Navigate to Application
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              Step - 2 In your workspace, you will notice an existing
              application.
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              Step - 3 Click Three dots, and click on Delete option of an
              existing application.
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              Step - 4 It will ask for the confirmation, write Delete in the
              textbox, and click Delete to delete an application.
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              It will delete an application.
            </p>
            <img
              src={deleteApplication}
              alt="deleteApplication"
              className="border"
            />
          </div>

          <SubTitle
            subtitle="Connect Bot with an application"
            hrefLink="connect-bot-with-an-application"
          />
          <div className="mb-8">
            <p className="mb-2 text-lg text-black font-medium">
              Step - 1 Navigate to Application
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              Step - 2 In your application workspace, you will notice an
              existing application.
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              Step - 3 Click on an existing application, you will move to the
              detail of the application.
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              Step - 4 Select the Bot from the Bot Connect drop-down.
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              Step - 5 Click on connect.
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              Bot connected successfully.
            </p>
            <img src={connectBot} alt="connectBot" className="border" />
          </div>

          <SubTitle
            subtitle="Disconnect Bot from an application"
            hrefLink="disconnect-bot-from-an-application"
          />
          <div className="mb-8">
            <p className="mb-2 text-lg text-black font-medium">
              Step - 1 Navigate to Application
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              Step - 2 In your application workspace, you will notice an
              existing application.
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              Step - 3 Click on an existing application, you will move to the
              detail of the application.
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              Step - 4 Click on Disconnect.
            </p>
            <img src={disConnectBot} alt="disConnectBot" className="border" />
          </div>

          <SubTitle
            subtitle="Connect omnichannel with an application"
            hrefLink="connect-omnichannel-with-an-application"
          />
          <div className="mb-8">
            <p className="mb-2 text-lg text-black font-medium">
              Step - 1 Navigate to Application
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              Step - 2 In your application workspace, you will notice an
              existing application.
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              Step - 3 Click on an existing application, you will move to the
              detail of the application.
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              Step - 4 You will notice configured social channel in lower part.
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              Step - 5 Click connect desired social channel.
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              Channel connected successfully.
            </p>
            <img
              src={connectSocialChannel}
              alt="connectSocialChannel"
              className="border"
            />
          </div>

          <SubTitle
            subtitle="Disconnect omnichannel from an application"
            hrefLink="disconnect-omnichannel-from-an-application"
          />
          <div className="mb-8">
            <p className="mb-2 text-lg text-black font-medium">
              Step - 1 Navigate to Application
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              Step - 2 In your application workspace, you will notice an
              existing application.
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              Step - 3 Click on an existing application, you will move to the
              detail of the application.
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              Step - 4 You will notice connected social channel in lower part.
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              Step - 5 Click disconnect the connected social channel.
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              Channel disconnected successfully.
            </p>
            <img
              src={disconnectSocialChannel}
              alt="disconnectSocialChannel"
              className="border"
            />
          </div>

          <SubTitle subtitle="Connect Web" hrefLink="connect-web" />
          <div className="mb-8">
            <p className="mb-2 text-lg text-black font-medium">
              Step - 1 Navigate to Application.
            </p>
            <img
              src={configure_Widget1}
              alt="configure_Widget1"
              className="border"
            />
          </div>
          <div className="mb-8">
            <p className="mb-2 text-lg text-black font-medium">
              Step - 2 In your application workspace, you will notice an
              existing application.
            </p>
            <img
              src={configure_Widget2}
              alt="configure_Widget2"
              className="border"
            />
          </div>
          <div className="mb-8">
            <p className="mb-2 text-lg text-black font-medium">
              Step - 3 Click on an existing application, you will move to the
              detail of the application.
            </p>
            <img
              src={configure_Widget3}
              alt="configure_Widget3"
              className="border"
            />
          </div>
          <div className="mb-8">
            <p className="mb-2 text-lg text-black font-medium">
              Step - 4 Click on Web Widget and Copy script and paste it to the
              head part of the website.
            </p>
            <img
              src={configure_Widget4}
              alt="configure_Widget4"
              className="border mb-1"
            />
            <p className="mb-2 text-lg font-medium">
              <span className="text-red-500">Note:</span> Please add above
              copied script into the header section (Head tag) of your website.
            </p>
          </div>
          <div className="mb-8">
            <p className="mb-2 text-lg text-black font-medium">
              Copy your website URL.
            </p>
          </div>
          <div className="mb-8">
            <p className="mb-2 text-lg text-black font-medium">
              Step - 6 Add the Domain in web Widget of XUnified, and Press
              Enter.
            </p>
            <img
              src={configure_Widget5}
              alt="configure_Widget5"
              className="border"
            />
          </div>
          <div className="mb-8">
            <p className="mb-2 text-lg text-black font-medium">
              Step - 7 Save Domain.
            </p>
            <img
              src={configure_Widget6}
              alt="configure_Widget6"
              className="border"
            />
          </div>

          <SubTitle subtitle="Configure Widget" hrefLink="configure-widget" />
          <div className="mb-8">
            <p className="mb-2 text-lg text-black font-medium">
              Step - 1 Navigate to Application
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              Step - 2 In your application workspace, you will notice an
              existing application.
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              Step - 3 Click on an existing application, you will move to the
              detail of the application.
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              Step - 4 Click on Web Widget and click on View or Edit widget.
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              Step - 5 Set Welcome, Survey, Chat Widget & Styling and Click
              Submit.
            </p>
            <img
              src={configureWidget}
              alt="configureWidget"
              className="border"
            />
          </div>

          <div className="flex justify-between">
            <PreviousBtn path="/dashbord" btnText="Dashbord" />
            <NextBtn path="/omnichannel" btnText="Omnichannel" />
          </div>
        </div>
        <RightBar rightBarMenu={rightBarMenu} />
      </div>
    </>
  );
}

export default Application;
