import React from "react";
import ScrollActive from "../../components/ScrollActive";
import Topbar from "../../components/Topbar";
import Title from "../../components/Title";
import PreviousBtn from "../../components/PreviousBtn";
import NextBtn from "../../components/NextBtn";
import RightBar from "../../components/RightBar";
import SubTitle from "../../components/SubTitle";
import add_new_contact from "../../images/contactsimg/add_new_contact.gif";
import edit_contact from "../../images/contactsimg/edit_contact.gif";
import delete_contact from "../../images/contactsimg/delete_contact.gif";

const rightBarMenu = [
  {
    tolink: "add-new-contacts",
    submenutext: "Add New Contacts",
  },
  {
    tolink: "edit-contacts",
    submenutext: "Edit Contacts",
  },
  {
    tolink: "delete-contacts",
    submenutext: "Delete Contacts",
  },
];

function Contacts() {
  return (
    <>
      <ScrollActive rightBarMenu={rightBarMenu} />
      <Topbar rightBarMenu={rightBarMenu} />

      <div className="lg:flex gap-6 sm:p-8 p-5 text-gray-600">
        <div className="flex-1">
          <Title title="Contacts" />
          <p className="mb-5">
            The Contact menu in XUnified allows you to efficiently manage all
            your contacts. From this section, you can:
          </p>
          <ul className="mb-5 pl-5 space-y-2">
            <li className="list-disc">
              <span className="text-black font-medium">Add New Contacts:</span>{" "}
              Quickly add new contacts to your list.
            </li>
            <li className="list-disc">
              <span className="text-black font-medium">
                Edit Existing Contacts:
              </span>{" "}
              Update contact details as needed.
            </li>
            <li className="list-disc">
              <span className="text-black font-medium">Delete Contacts:</span>{" "}
              Remove contacts that are no longer needed.
            </li>
            <li className="list-disc">
              <span className="text-black font-medium">
                Change Contacts View:
              </span>{" "}
              Adjust the display settings to view your contacts in the way that
              best suits your needs.
            </li>
          </ul>
          <p className="mb-5">
            By clicking on individual contacts, you can access and manage
            detailed information, including adding email addresses, location,
            office details, and reviewing call history. This comprehensive
            management capability ensures you have all the information you need
            at your fingertips.
          </p>
          <SubTitle subtitle="Add New Contacts" hrefLink="add-new-contacts" />
          <p className="mb-5">
            The Add New Contact feature allows you to easily create and store
            new contact information. To add a new contact, simply input the
            basic details, including First Name, Last Name, Phone Number, and
            Email Address. If desired, you can also assign additional phone
            numbers to specific lists for better organization. This feature
            helps you keep your contact information up-to-date and
            well-organized.
          </p>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 1 Navigate to Contacts. It will open Contacts workspace
            </p>
            <p>
              Begin by going to the Contacts menu. This will open the Contacts
              workspace.
            </p>
          </div>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 2 Click on Add New Contact button in top right corner
            </p>
            <p>
              In the top right corner of the Contacts workspace, click the Add
              New Contact button.
            </p>
          </div>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 3 Enter First name, Last name, Phone number, Email, and Add
              to list in the Add new contact pop-up
            </p>
            <p>
              In the Add New Contact pop-up, fill in the First Name, Last Name,
              Phone Number, Email, and select a list to add the contact to, if
              desired.
            </p>
          </div>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 4 Click on Add Contact button
            </p>
            <p>
              Once you have entered all the details, click the Add Contact
              button to save the new contact.
            </p>
          </div>
          <img
            src={add_new_contact}
            alt="add_new_contact"
            className="border mb-10"
          />

          <SubTitle subtitle="Edit Contacts" hrefLink="edit-contacts" />
          <p className="mb-5">
            The Edit Contact feature allows you to update and modify the details
            of an existing contact. You can easily change basic information such
            as First Name, Last Name, Phone Number, and Email Address. This
            feature ensures that your contact details remain accurate and
            up-to-date, facilitating effective communication and management.
          </p>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 1 Navigate to Contacts
            </p>
            <p>
              Begin by accessing the Contacts menu. This will open your Contacts
              workspace.
            </p>
          </div>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 2 In your workspace, you will notice an existing Contacts
            </p>
            <p>Find the contact you wish to edit within the workspace.</p>
          </div>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 3 Click Three dots, and click on Edit option of an existing
              contact
            </p>
            <p>
              Next to the contact, click on the three dots (more options) and
              select the Edit option.
            </p>
          </div>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 4 It will open Edit Contact pop-up
            </p>
            <p>This action will open the Edit Contact pop-up window.</p>
          </div>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 5 Change the contact details you wish and click on update
              contact
            </p>
            <p>
              Make the necessary changes to the contact details and click Update
              Contact to save your changes.
            </p>
          </div>
          <img src={edit_contact} alt="edit_contact" className="border mb-10" />

          <SubTitle subtitle="Delete Contacts" hrefLink="delete-contacts" />
          <p className="mb-5">
            The Delete Contact feature allows you to remove contacts from your
            list when they are no longer needed. By selecting the Delete option,
            you will be prompted to confirm your action to ensure that contacts
            are only deleted intentionally. This helps you manage your contact
            list effectively by removing outdated or irrelevant entries.
          </p>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 1 Navigate to Contacts
            </p>
            <p>
              Begin by accessing the Contacts menu. This will open your Contacts
              workspace.
            </p>
          </div>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 2 In your workspace, you will notice an existing Contacts
            </p>
            <p>Find the contact you wish to delete within the workspace.</p>
          </div>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 3 Click Three dots, and click on Delete option of an
              existing contact
            </p>
            <p>
              Next to the contact, click on the three dots (more options) and
              select the Delete option.
            </p>
          </div>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 4 It will ask for the confirmation, click on Delete to
              delete an application
            </p>
            <p>
              A confirmation prompt will appear. Click Delete to confirm and
              remove the contact from your list.
            </p>
          </div>
          <img
            src={delete_contact}
            alt="delete_contact"
            className="border mb-10"
          />

          <div className="flex justify-between">
            <PreviousBtn path="/analytics" btnText="Analytics" />
            <NextBtn path="/logs" btnText="Logs" />
          </div>
        </div>
        <RightBar rightBarMenu={rightBarMenu} />
      </div>
    </>
  );
}

export default Contacts;
