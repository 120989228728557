import React from "react";
import ScrollActive from "../../components/ScrollActive";
import Topbar from "../../components/Topbar";
import Title from "../../components/Title";
import PreviousBtn from "../../components/PreviousBtn";
import NextBtn from "../../components/NextBtn";
import RightBar from "../../components/RightBar";
import SubTitle from "../../components/SubTitle";
import { Link } from "react-router-dom";
import apikeygif from "../../images/botconfigimg/APIkey.gif";
import deleteapikeygif from "../../images/botconfigimg/APIkeydelete.gif";

const rightBarMenu = [
  {
    tolink: "getting-openai-api-key",
    submenutext: "Getting OpenAI API Key",
  },
  {
    tolink: "getting-gemini-api-key",
    submenutext: "Getting Gemini API Key",
  },
  {
    tolink: "adding-api-key",
    submenutext: "Adding API Key",
  },
  {
    tolink: "deleting-api-key",
    submenutext: "Deleting API Key",
  },
];

function ApiKey() {
  return (
    <>
      <ScrollActive rightBarMenu={rightBarMenu} />
      <Topbar rightBarMenu={rightBarMenu} />

      <div className="lg:flex gap-6 sm:p-8 p-5 text-gray-600">
        <div className="flex-1">
          <Title title="API Key" />

          <SubTitle
            subtitle="Getting OpenAI API Key"
            hrefLink="getting-openai-api-key"
          />
          <ul className="mb-5 pl-5 space-y-2">
            <li className="list-disc">
              <span className="text-black font-medium">Sign Up:</span> If you do
              not have an OpenAI account, register by visiting the{" "}
              <Link
                to="https://auth.openai.com/authorize?issuer=auth0.openai.com&client_id=DRivsnm2Mu42T3KOpqdtwB3NYviHYzwD&audience=https%3A%2F%2Fapi.openai.com%2Fv1&redirect_uri=https%3A%2F%2Fplatform.openai.com%2Fauth%2Fcallback&device_id=c2f37be0-fa84-4c98-b8a5-89426f36cda8&screen_hint=signup&max_age=0&scope=openid+profile+email+offline_access&response_type=code&response_mode=query&state=M1otSF9VYk5qUlFmQUtTYzB4a0JNQmxYN2lrVDR0WTMyczZFZkx0cGRqcQ%3D%3D&nonce=YTBZSXd0ODI4cFpVRGJHQ05XMS5WUktBaHE2QzZSREJiZ3Q1NFhTRlp0NA%3D%3D&code_challenge=e98owp49zBjMMqw_yY1HzDRbzEpO4xbK1OtrIFAvBNA&code_challenge_method=S256&auth0Client=eyJuYW1lIjoiYXV0aDAtc3BhLWpzIiwidmVyc2lvbiI6IjEuMjEuMCJ9&flow=control"
                target="_blank"
                className="text-blue-600 font-medium underline"
              >
                OpenAI website
              </Link>{" "}
              and signing up.
            </li>
            <li className="list-disc">
              <span className="text-black font-medium">Log In:</span> Access the{" "}
              <Link
                to="https://auth.openai.com/authorize?issuer=auth0.openai.com&client_id=DRivsnm2Mu42T3KOpqdtwB3NYviHYzwD&audience=https%3A%2F%2Fapi.openai.com%2Fv1&redirect_uri=https%3A%2F%2Fplatform.openai.com%2Fauth%2Fcallback&device_id=c2f37be0-fa84-4c98-b8a5-89426f36cda8&max_age=0&scope=openid+profile+email+offline_access&response_type=code&response_mode=query&state=M0Rja3RHdnc3V2RzVlZGMENjaWw0cFU1NkN3TXREc2hyV2pqTWU1dzNiYw%3D%3D&nonce=SVBCX0ZfREZIU2tNREJ2NC1JYTFUX055ZXEwajhRY35ZYVJvOW1BYjhPTw%3D%3D&code_challenge=jHv-MLtyR9mZnU61N1F8zkS2EAvsZZFO6_H1ErKrBSM&code_challenge_method=S256&auth0Client=eyJuYW1lIjoiYXV0aDAtc3BhLWpzIiwidmVyc2lvbiI6IjEuMjEuMCJ9&flow=control"
                target="_blank"
                className="text-blue-600 font-medium underline"
              >
                OpenAI Dashboard
              </Link>{" "}
              by logging into your OpenAI account.
            </li>
            <li className="list-disc">
              <span className="text-black font-medium">
                Access API Section:
              </span>{" "}
              Once logged in, navigate to the API section of the dashboard to
              manage your API keys.
            </li>
            <li className="list-disc">
              <span className="text-black font-medium">Create API Key:</span> If
              you already have an account but need an API key, click on "Create
              an OpenAI API key."
            </li>
            <li className="list-disc">
              <span className="text-black font-medium">Integrate API Key:</span>{" "}
              Incorporate the API key into your application. Consult OpenAI's
              API documentation for details on structuring your requests and
              managing responses effectively.
            </li>
          </ul>

          <SubTitle
            subtitle="Getting Gemini API Key"
            hrefLink="getting-gemini-api-key"
          />
          <p className="mb-2 text-gray-600">
            Follow these simple steps to obtain your Gemini API key and add it
            to our platform:
          </p>
          <ul className="mb-5 pl-5 space-y-2">
            <li className="list-disc">
              <span className="text-black font-medium">
                Visit the API Key Page:
              </span>{" "}
              Go to the{" "}
              <Link
                to="https://aistudio.google.com/app/apikey"
                target="_blank"
                className="text-blue-600 font-medium underline"
              >
                Google AI Studio API Key page
              </Link>{" "}
            </li>
            <li className="list-disc">
              <span className="text-black font-medium">
                Create a New API Key:
              </span>{" "}
              Click on the "Create API key" button.
            </li>
            <li className="list-disc">
              <span className="text-black font-medium">
                Select Your Google Cloud Project:
              </span>{" "}
              In the search bar, type and select your desired Google Cloud
              project.
            </li>
            <li className="list-disc">
              <span className="text-black font-medium">
                Create API Key in Existing Project:
              </span>{" "}
              Click on "Create API key in existing project".
            </li>
            <li className="list-disc">
              <span className="text-black font-medium">Copy Your API Key:</span>{" "}
              Once generated, copy the API key.
            </li>
            <li className="list-disc">
              <span className="text-black font-medium">
                Add API Key to Our Platform:
              </span>{" "}
              Paste the copied API key into the designated field on our
              platform.
            </li>
          </ul>

          <SubTitle subtitle="Adding API Key" hrefLink="adding-api-key" />
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 1 Navigate to Settings
            </p>
            <p>
              Look for a "Settings" option, usually found in the navigation
              menu.
            </p>
          </div>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 2 Navigate to API Key
            </p>
            <p>
              Within the Settings page, look for a tab "API Key". This area is
              dedicated to handling API keys, including adding, deleting, or
              viewing existing keys.
            </p>
          </div>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 3 Click on Add New Key
            </p>
            <p>
              Find and click on the "Add New Key" button. This action will start
              the process of creating a new API key for your application.
            </p>
          </div>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 4 Add Name, Secret Key and select Platform, in Add New API
              Key pop-up
            </p>
            <p className="mb-2">
              A pop-up form will appear where you'll need to provide the
              following information:
            </p>
            <ul className="mb-5 pl-5 space-y-2">
              <li className="list-disc">
                <span className="text-black font-medium">Name:</span> Enter a
                descriptive name for the API key. This will help you identify it
                later, especially if you have multiple keys.
              </li>
              <li className="list-disc">
                <span className="text-black font-medium">Secret Key:</span>{" "}
                Input the secret key provided by OpenAI. This key is essential
                for authenticating your requests to the OpenAI API.
              </li>
              <li className="list-disc">
                <span className="text-black font-medium">Select Platform:</span>{" "}
                Choose the platform or application where you intend to use the
                API key. This could be a specific environment like "Development"
                or "Production."
              </li>
            </ul>
          </div>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 5 Click on Add
            </p>
            <p className="mb-2">
              Once you have filled out all the necessary information, click the
              "Add" button to finalize the creation of the new API key. This
              action will save your key details and make the key available for
              use.
            </p>
          </div>
          <img src={apikeygif} alt="apikeygif" className="border mb-10" />

          <SubTitle subtitle="Deleting API Key" hrefLink="deleting-api-key" />
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 1 Navigate to Settings
            </p>
            <p>
              Look for a "Settings" option, usually found in the navigation
              menu.
            </p>
          </div>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 2 Navigate to API Key
            </p>
            <p>
              Within the Settings page, look for a tab "API Key". This area is
              dedicated to handling API keys, including adding, deleting, or
              viewing existing keys.
            </p>
          </div>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 3 Click on Delete
            </p>
            <p>
              In the list of API keys, identify the key you want to delete.
              Click on the "Delete" icon next to the API key you wish to remove.
              This action signals your intent to delete the key.
            </p>
          </div>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 4 It will ask for the confirmation in pop-up, Click on
              Delete button
            </p>
            <p className="mb-2">
              A pop-up window will appear, asking you to confirm the deletion.
              Click on the "Delete" button within the pop-up to confirm your
              decision. By doing this, you are permanently removing the API key
              from your account.
            </p>
          </div>
          <img
            src={deleteapikeygif}
            alt="deleteapikeygif"
            className="border mb-10"
          />

          <div className="flex justify-between">
            <PreviousBtn
              path="/omnichannel/textMagic-integration"
              btnText="TextMagic Integration"
            />
            <NextBtn
              path="/bot-configuration/dialogflow"
              btnText="Dialogflow"
            />
          </div>
        </div>

        <RightBar rightBarMenu={rightBarMenu} />
      </div>
    </>
  );
}

export default ApiKey;
