import React from "react";
import ScrollActive from "../../components/ScrollActive";
import Topbar from "../../components/Topbar";
import SubTitle from "../../components/SubTitle";
import Title from "../../components/Title";
import PreviousBtn from "../../components/PreviousBtn";
import NextBtn from "../../components/NextBtn";
import RightBar from "../../components/RightBar";
import fundamentals1 from "../../images/fundamentals/fundamentals1.jpg";
import fundamentals01 from "../../images/fundamentals/fundamentals01.jpg";
import fundamentals2 from "../../images/fundamentals/fundamentals2.jpg";
import fundamentals3 from "../../images/fundamentals/fundamentals3.jpg";
import fundamentals4 from "../../images/fundamentals/fundamentals4.jpg";
import fundamentals5 from "../../images/fundamentals/fundamentals5.jpg";
import fundamentals6 from "../../images/fundamentals/fundamentals6.jpg";
import fundamentals7 from "../../images/fundamentals/fundamentals7.jpg";
import fundamentals8 from "../../images/fundamentals/fundamentals8.jpg";
import fundamentals9 from "../../images/fundamentals/fundamentals9.jpg";
import fundamentals10 from "../../images/fundamentals/fundamentals10.jpg";
import fundamentals11 from "../../images/fundamentals/fundamentals11.jpg";
import fundamentals12 from "../../images/fundamentals/fundamentals12.jpg";
import fundamentals13 from "../../images/fundamentals/fundamentals13.jpg";
import fundamentals14 from "../../images/fundamentals/fundamentals14.jpg";
import fundamentals15 from "../../images/fundamentals/fundamentals15.jpg";
import {
  aiStudiokey,
  analyticskey,
  applicationkey,
  botConfigurationkey,
  Campaignkey,
  contactskey,
  dashboardKey,
  dialogflowkey,
  docskey,
  livechatkey,
  llmkey,
  logskey,
  omnichannelkey,
  settingskey,
  supportskey,
  xunifiedKey,
} from "./KeyData";

const rightBarMenu = [
  {
    tolink: "introduction-to-xunified",
    submenutext: "Introduction to XUnified",
  },
  {
    tolink: "introduction-to-dashboard",
    submenutext: "Introduction to Dashboard",
  },
  {
    tolink: "introduction-to-application",
    submenutext: "Introduction to Application",
  },
  {
    tolink: "introduction-to-omnichannel",
    submenutext: "Introduction to Omnichannel",
  },
  {
    tolink: "introduction-to-bot-configuration",
    submenutext: "Introduction to Bot Configuration",
    children: [
      {
        tolink: "introduction-to-dialogflow",
        submenutext: "1. Introduction to Dialogflow",
      },
      {
        tolink: "introduction-to-llm",
        submenutext: "2. Introduction to LLM",
      },
      {
        tolink: "introduction-ai-studio",
        submenutext: "3. Introduction AI Studio",
      },
    ],
  },
  {
    tolink: "introduction-to-campaign",
    submenutext: "Introduction to Campaign",
  },
  {
    tolink: "introduction-to-live-chat",
    submenutext: "Introduction to Live Chat",
  },
  {
    tolink: "introduction-to-analytics",
    submenutext: "Introduction to Analytics",
  },
  {
    tolink: "introduction-to-contacts",
    submenutext: "Introduction to Contacts",
  },
  {
    tolink: "introduction-to-logs",
    submenutext: "Introduction to Logs",
  },
  {
    tolink: "introduction-to-settings",
    submenutext: "Introduction to Settings",
  },
  {
    tolink: "introduction-to-docs",
    submenutext: "Introduction to Docs",
  },
  {
    tolink: "introduction-to-supports",
    submenutext: "Introduction to Supports",
  },
];

const KeyDesign = ({ keyitem }) => (
  <div className="bg-gray-50 p-4 rounded-md border">
    <p className="text-black mb-3">{keyitem.keytitle}</p>
    <p className="text-sm">{keyitem.keycontent}</p>
  </div>
);

function Fundamentals() {
  return (
    <>
      <ScrollActive rightBarMenu={rightBarMenu} />
      <Topbar rightBarMenu={rightBarMenu} />

      <div className="lg:flex gap-6 sm:p-8 p-5 text-gray-600">
        <div className="flex-1">
          <Title title="Fundamentals" />
          <SubTitle
            subtitle="Introduction to XUnified"
            hrefLink="introduction-to-xunified"
          />
          <p className="mb-5">
            XUnified, your comprehensive solution for customer engagement and
            communication needs. With XUnified, you can unlock your growth
            opportunities and enhance your business's efficiency in connecting
            with your audience.
          </p>
          <div className="mb-8">
            <p className="mb-2 text-lg text-black font-medium">
              Step - 1 Click on Get Started for Free
            </p>
            <img src={fundamentals1} alt="fundamentals1" className="border" />
          </div>
          <div className="mb-5">
            <p className="mb-2 text-lg text-black font-medium">
              Key Features of XUnified
            </p>
            <div className="grid sm:grid-cols-2 grid-cols-1 gap-5">
              {xunifiedKey.map((item, index) => (
                <KeyDesign keyitem={item} key={index} />
              ))}
            </div>
          </div>
          <p className="mb-5">
            With XUnified, you have everything you need to streamline your
            customer engagement processes and take your business to new heights.
            Welcome to a world of seamless communication and enhanced customer
            experiences.
          </p>

          <SubTitle
            subtitle="Introduction to Dashboard"
            hrefLink="introduction-to-dashboard"
          />
          <p className="mb-5">
            Welcome to our platform! We're excited to provide you with a
            comprehensive dashboard that offers insights and tools to enhance
            your experience. Upon signing in, you'll be seamlessly redirected to
            your personalized dashboard, where you can access key information
            and features at a glance.
          </p>
          <div className="mb-8">
            <img src={fundamentals01} alt="fundamentals01" className="border" />
          </div>
          <div className="mb-5">
            <p className="mb-2 text-lg text-black font-medium">
              Key Features of Dashboard
            </p>
            <div className="grid sm:grid-cols-2 grid-cols-1 gap-5">
              {dashboardKey.map((item, index) => (
                <KeyDesign keyitem={item} key={index} />
              ))}
            </div>
          </div>

          <SubTitle
            subtitle="Introduction to Application"
            hrefLink="introduction-to-application"
          />
          <p className="mb-5">
            In the ever-evolving landscape of customer engagement, XUnified
            emerges as the ultimate solution, empowering businesses to craft
            tailored applications and seamlessly connect with customers across
            various channels.
          </p>
          <p className="mb-5">
            With a focus on flexibility, customization, and integration,
            XUnified is revolutionizing the way businesses engage with their
            audience.
          </p>
          <div className="mb-8">
            <img src={fundamentals2} alt="fundamentals2" className="border" />
          </div>
          <div className="mb-5">
            <p className="mb-2 text-lg text-black font-medium">
              Key Features of Application
            </p>
            <div className="grid sm:grid-cols-2 grid-cols-1 gap-5">
              {applicationkey.map((item, index) => (
                <KeyDesign keyitem={item} key={index} />
              ))}
            </div>
          </div>

          <SubTitle
            subtitle="Introduction to Omnichannel"
            hrefLink="introduction-to-omnichannel"
          />
          <p className="mb-5">
            In today's fast-paced digital landscape, customer engagement is key
            to success.
          </p>
          <p className="mb-5">
            With XUnified's Omni-Channel Connectivity, businesses can
            effortlessly connect with their customers across a diverse range of
            channels, ensuring a seamless and consistent experience at every
            touchpoint.
          </p>
          <div className="mb-8">
            <img src={fundamentals3} alt="fundamentals3" className="border" />
          </div>
          <div className="mb-5">
            <p className="mb-2 text-lg text-black font-medium">
              Key Features of Omnichannel
            </p>
            <div className="grid sm:grid-cols-2 grid-cols-1 gap-5">
              {omnichannelkey.map((item, index) => (
                <KeyDesign keyitem={item} key={index} />
              ))}
            </div>
          </div>

          <SubTitle
            subtitle="Introduction to Bot Configuration"
            hrefLink="introduction-to-bot-configuration"
          />
          <p className="mb-5">
            In the current dynamic business landscape, offering outstanding
            customer service is critical to the success of any enterprise.
          </p>
          <p className="mb-5">
            With XUnified's advanced Bot Configuration feature, businesses can
            streamline operations, enhance customer self-service capabilities,
            and provide round-the-clock support to their users.
          </p>
          <div className="mb-8">
            <img src={fundamentals4} alt="fundamentals4" className="border" />
          </div>
          <div className="mb-5">
            <p className="mb-2 text-lg text-black font-medium">
              Key Features of Bot Configuration
            </p>
            <div className="grid sm:grid-cols-2 grid-cols-1 gap-5">
              {botConfigurationkey.map((item, index) => (
                <KeyDesign keyitem={item} key={index} />
              ))}
            </div>
          </div>

          <SubTitle
            subtitle="Introduction to Dialogflow"
            hrefLink="introduction-to-dialogflow"
          />
          <p className="mb-5">
            Elevate your conversational AI experiences to new heights with
            seamless integration of Dialogflow.
          </p>
          <p className="mb-5">
            Dialogflow, a robust Natural Language Processing (NLP) platform,
            empowers businesses to develop applications tailored for
            conversational experiences across multiple languages and platforms.
          </p>
          <div className="mb-8">
            <img src={fundamentals5} alt="fundamentals5" className="border" />
          </div>
          <div className="mb-5">
            <p className="mb-2 text-lg text-black font-medium">
              Key Features of Dialogflow
            </p>
            <div className="grid sm:grid-cols-2 grid-cols-1 gap-5">
              {dialogflowkey.map((item, index) => (
                <KeyDesign keyitem={item} key={index} />
              ))}
            </div>
          </div>

          <SubTitle
            subtitle="Introduction to LLM"
            hrefLink="introduction-to-llm"
          />
          <p className="mb-5">
            Discover the boundless capabilities of natural language processing
            (NLP), including deep learning and machine learning algorithms, to
            understand and generate human-like text with LLM integration.
          </p>
          <p className="mb-5">
            In essence, LLM empowers users to unlock the full potential of AI by
            providing intuitive tools and resources to craft, train, and deploy
            sophisticated models for a myriad of applications.
          </p>
          <div className="mb-8">
            <img src={fundamentals6} alt="fundamentals6" className="border" />
          </div>
          <div className="mb-5">
            <p className="mb-2 text-lg text-black font-medium">
              Key Features of LLM
            </p>
            <div className="grid sm:grid-cols-2 grid-cols-1 gap-5">
              {llmkey.map((item, index) => (
                <KeyDesign keyitem={item} key={index} />
              ))}
            </div>
          </div>

          <SubTitle
            subtitle="Introduction AI Studio"
            hrefLink="introduction-ai-studio"
          />
          <p className="mb-5">
            Unlocking the potential of AI Studio integration empowers users,
            both developers and non-developers, to seamlessly create, experiment
            with, and deploy AI models and applications.
          </p>
          <p className="mb-5">
            AI Studio serves as a powerful no-code conversation designer,
            facilitating the design, creation, and deployment of virtual
            assistants capable of operating in natural language.
          </p>
          <div className="mb-8">
            <img src={fundamentals7} alt="fundamentals7" className="border" />
          </div>
          <div className="mb-5">
            <p className="mb-2 text-lg text-black font-medium">
              Key Features of AI Studio
            </p>
            <div className="grid sm:grid-cols-2 grid-cols-1 gap-5">
              {aiStudiokey.map((item, index) => (
                <KeyDesign keyitem={item} key={index} />
              ))}
            </div>
          </div>

          <SubTitle
            subtitle="Introduction to Campaign"
            hrefLink="introduction-to-campaign"
          />
          <p className="mb-5">
            With XUnified's Broadcast feature, businesses can effortlessly
            create, schedule, and manage broadcasts to engage their audience
            effectively.
          </p>
          <p className="mb-5">
            Whether it's important announcements, promotions, or updates,
            XUnified empowers users to deliver timely and impactful messages
            across multiple channels simultaneously.
          </p>
          <div className="mb-8">
            <img src={fundamentals8} alt="fundamentals8" className="border" />
          </div>
          <div className="mb-5">
            <p className="mb-2 text-lg text-black font-medium">
              Key Features of Campaign
            </p>
            <div className="grid sm:grid-cols-2 grid-cols-1 gap-5">
              {Campaignkey.map((item, index) => (
                <KeyDesign keyitem={item} key={index} />
              ))}
            </div>
          </div>

          <SubTitle
            subtitle="Introduction to Live Chat"
            hrefLink="introduction-to-live-chat"
          />
          <p className="mb-5">
            Live chat functionality has revolutionized the way individuals and
            businesses communicate in real-time.
          </p>
          <div className="mb-8">
            <img src={fundamentals9} alt="fundamentals9" className="border" />
          </div>
          <div className="mb-5">
            <p className="mb-2 text-lg text-black font-medium">
              Key Features of Live Chat
            </p>
            <div className="grid sm:grid-cols-2 grid-cols-1 gap-5">
              {livechatkey.map((item, index) => (
                <KeyDesign keyitem={item} key={index} />
              ))}
            </div>
          </div>

          <SubTitle
            subtitle="Introduction to Analytics"
            hrefLink="introduction-to-analytics"
          />
          <p className="mb-5">
            XUnified Analytics offers powerful tools to understand and optimize
            user interactions and messaging across multiple channels.
          </p>
          <p className="mb-5">
            By providing detailed insights through intuitive graphs and reports,
            our Analytics feature helps you track user engagement, message
            counts, and performance metrics. Empower your team with the data
            needed to enhance communication and drive better results.
          </p>
          <div className="mb-8">
            <img src={fundamentals10} alt="fundamentals10" className="border" />
          </div>
          <div className="mb-5">
            <p className="mb-2 text-lg text-black font-medium">
              Key Features of Analytics
            </p>
            <div className="grid sm:grid-cols-2 grid-cols-1 gap-5">
              {analyticskey.map((item, index) => (
                <KeyDesign keyitem={item} key={index} />
              ))}
            </div>
          </div>

          <SubTitle
            subtitle="Introduction to Contacts"
            hrefLink="introduction-to-contacts"
          />
          <p className="mb-5">
            The Contact feature allows users to efficiently manage their
            contacts, ensuring streamlined and organized handling of all
            personal and business connections. This feature provides users with
            the ability to create, view, edit, and delete contacts, facilitating
            seamless management of contact information.
          </p>
          <div className="mb-8">
            <img src={fundamentals11} alt="fundamentals11" className="border" />
          </div>
          <div className="mb-5">
            <p className="mb-2 text-lg text-black font-medium">
              Key Features of Contacts
            </p>
            <div className="grid sm:grid-cols-2 grid-cols-1 gap-5">
              {contactskey.map((item, index) => (
                <KeyDesign keyitem={item} key={index} />
              ))}
            </div>
          </div>

          <SubTitle
            subtitle="Introduction to Logs"
            hrefLink="introduction-to-logs"
          />
          <p className="mb-5">
            Welcome to our platform's activity logs feature! With this tool,
            users can easily track and monitor all activities within the system
            in real-time. From sign-ins to application creations, bot
            configurations, and broadcast schedules, our comprehensive logs
            provide valuable insights into user interactions and system
            operations.
          </p>
          <p className="mb-5">
            Overall, our activity logs feature empowers users to monitor
            platform activities effectively, ensuring transparency,
            accountability, and streamlined operations.
          </p>
          <div className="mb-8">
            <img src={fundamentals12} alt="fundamentals12" className="border" />
          </div>
          <div className="mb-5">
            <p className="mb-2 text-lg text-black font-medium">
              Key Features of Logs
            </p>
            <div className="grid sm:grid-cols-2 grid-cols-1 gap-5">
              {logskey.map((item, index) => (
                <KeyDesign keyitem={item} key={index} />
              ))}
            </div>
          </div>

          <SubTitle
            subtitle="Introduction to Settings"
            hrefLink="introduction-to-settings"
          />
          <p className="mb-5">
            Welcome to the Settings menu, your one-stop destination for managing
            your profile, user invitations, API keys, and subscription details.
            Here, users have the flexibility to customize their personal and
            company profiles, invite team members, handle LLM API keys, and
            manage subscription payments seamlessly.
          </p>
          <div className="mb-8">
            <img src={fundamentals13} alt="fundamentals13" className="border" />
          </div>
          <div className="mb-5">
            <p className="mb-2 text-lg text-black font-medium">
              Key Features of Settings
            </p>
            <div className="grid sm:grid-cols-2 grid-cols-1 gap-5">
              {settingskey.map((item, index) => (
                <KeyDesign keyitem={item} key={index} />
              ))}
            </div>
          </div>

          <SubTitle
            subtitle="Introduction to Docs"
            hrefLink="introduction-to-docs"
          />
          <p className="mb-5">
            Welcome to Docs, your comprehensive resource hub for exploring the
            features and functionalities of our platform. Whether you're a new
            user looking to get started or an experienced user seeking in-depth
            information, Docs provides a wealth of resources, including platform
            documentation, quick start guides, fundamentals, and much more. With
            easy navigation and detailed insights, Docs empowers users to unlock
            the full potential of our platform and make the most out of its
            capabilities.
          </p>
          <div className="mb-8">
            <img src={fundamentals14} alt="fundamentals14" className="border" />
          </div>
          <div className="mb-5">
            <p className="mb-2 text-lg text-black font-medium">
              Key Features of Settings
            </p>
            <div className="grid sm:grid-cols-2 grid-cols-1 gap-5">
              {docskey.map((item, index) => (
                <KeyDesign keyitem={item} key={index} />
              ))}
            </div>
          </div>

          <SubTitle
            subtitle="Introduction to Supports"
            hrefLink="introduction-to-supports"
          />
          <p className="mb-5">
            Welcome to Support, your dedicated space for addressing any concerns
            or inquiries related to the platform's features and functionalities.
            Whether you encounter technical issues, require assistance with
            configuration, or have suggestions for new features, Support
            provides a comprehensive solution to meet your needs. With ticketing
            capabilities and live chat support, users can seamlessly interact
            with our support team to resolve issues and enhance their experience
            with the platform.
          </p>
          <div className="mb-8">
            <img src={fundamentals15} alt="fundamentals15" className="border" />
          </div>
          <div className="mb-5">
            <p className="mb-2 text-lg text-black font-medium">
              Key Features of Supports
            </p>
            <div className="grid sm:grid-cols-2 grid-cols-1 gap-5">
              {supportskey.map((item, index) => (
                <KeyDesign keyitem={item} key={index} />
              ))}
            </div>
          </div>

          <div className="flex justify-between">
            <PreviousBtn path="/signup-signin" btnText="Sign Up & Sign In" />
            <NextBtn path="/dashbord" btnText="Dashbord" />
          </div>
        </div>

        <RightBar rightBarMenu={rightBarMenu} />
      </div>
    </>
  );
}

export default Fundamentals;
