import React from "react";
import ScrollActive from "../../components/ScrollActive";
import Topbar from "../../components/Topbar";
import Title from "../../components/Title";
import PreviousBtn from "../../components/PreviousBtn";
import NextBtn from "../../components/NextBtn";
import RightBar from "../../components/RightBar";
import SubTitle from "../../components/SubTitle";
import navigate_bot_configuration from "../../images/botconfigimg/dialogflowimg/navigate_bot_configuration.jpg";
import create_new_bot from "../../images/botconfigimg/dialogflowimg/create_new_bot.jpg";
import select_dialogflow from "../../images/botconfigimg/dialogflowimg/select_dialogflow.jpg";
import connect_dialogflow from "../../images/botconfigimg/dialogflowimg/connect_dialogflow.jpg";
import configuration_view from "../../images/botconfigimg/dialogflowimg/configuration_view.jpg";
import configuration_step1 from "../../images/botconfigimg/dialogflowimg/configuration_step1.jpg";
import select_account from "../../images/botconfigimg/dialogflowimg/select_account.jpg";
import click_continue from "../../images/botconfigimg/dialogflowimg/click_continue.jpg";
import configuration_step2 from "../../images/botconfigimg/dialogflowimg/configuration_step2.jpg";
import configuration_step2_continue from "../../images/botconfigimg/dialogflowimg/configuration_step2_continue.jpg";
import configuration_step4 from "../../images/botconfigimg/dialogflowimg/configuration_step4.jpg";
import configuration_step4_continue from "../../images/botconfigimg/dialogflowimg/configuration_step4_continue.jpg";
import configuration_step5 from "../../images/botconfigimg/dialogflowimg/configuration_step5.jpg";
import configuration_step5_submit from "../../images/botconfigimg/dialogflowimg/configuration_step5_submit.jpg";
import dialogflow_successfully from "../../images/botconfigimg/dialogflowimg/dialogflow_successfully.jpg";

const rightBarMenu = [
  {
    tolink: "step-1-navigate-to-bot-configuration",
    submenutext: "Step - 1 Navigate to Bot Configuration",
  },
  {
    tolink: "step-2-click-on-create-bot-button",
    submenutext: "Step - 2 Click on Create Bot button",
  },
  {
    tolink: "step-3-click-on-dialogflow",
    submenutext: "Step - 3 Click on Dialogflow",
  },
  {
    tolink: "step-4-click-on-connect-dialogflow",
    submenutext: "Step - 4 Click on Connect Dialogflow",
  },
  {
    tolink: "step-5-click-on-sign-in-with-google-in-dialogflow-configuration",
    submenutext:
      "Step - 5 Click on Sign in with google in Dialogflow configuration",
  },
  {
    tolink: "step-6-create-new-project-or-select-existing-project",
    submenutext: "Step - 6 Create new project or select existing project",
  },
  {
    tolink: "step-7-click-on-continue-with-existing-agent",
    submenutext: "Step - 7 Click on Continue with existing agent",
  },
  {
    tolink: "step-8-create-a-new-service-account",
    submenutext: "Step - 8 Create a new service account",
  },
  {
    tolink: "step-9-assign-the-role-to-selected-service-account",
    submenutext: "Step - 9 Assign the role to selected service account",
  },
  {
    tolink: "step-10-click-on-submit-in-dialogflow-configuration",
    submenutext: "Step - 10 Click on Submit in Dialogflow configuration",
  },
];

function Dialogflow() {
  return (
    <>
      <ScrollActive rightBarMenu={rightBarMenu} />
      <Topbar rightBarMenu={rightBarMenu} />

      <div className="lg:flex gap-6 sm:p-8 p-5 text-gray-600">
        <div className="flex-1">
          <Title title="Dialogflow" />
          <p className="mb-2">
            Dialogflow is a natural language understanding platform that enables
            the design and integration of conversational user interfaces into
            mobile apps, web applications, devices, and bots. By integrating
            Dialogflow, you can create a bot that understands and processes user
            intents, making your interaction with customers more dynamic and
            efficient. This guide will walk you through the steps to configure
            and connect a Dialogflow bot with your application.
          </p>
          <div className="mb-8">
            <SubTitle
              subtitle="Step - 1 Navigate to Bot Configuration"
              hrefLink="step-1-navigate-to-bot-configuration"
            />
            <p className="mb-2">
              Begin by accessing the Bot Configuration section within your
              application’s settings. This is where you will initiate the setup
              of your new bot.
            </p>
            <img
              src={navigate_bot_configuration}
              alt="navigate_bot_configuration"
              className="border"
            />
          </div>
          <div className="mb-8">
            <SubTitle
              subtitle="Step - 2 Click on Create Bot button in top right corner"
              hrefLink="step-2-click-on-create-bot-button"
            />
            <p className="mb-2">
              In the top right corner of the Bot Configuration page, you will
              find a Create Bot button. Click on it to start the bot creation
              process.
            </p>
            <img src={create_new_bot} alt="create_new_bot" className="border" />
          </div>
          <div className="mb-8">
            <SubTitle
              subtitle="Step - 3 Click on Dialogflow. It will open Dialogflow workspace"
              hrefLink="step-3-click-on-dialogflow"
            />
            <p className="mb-2">
              After clicking the Create Bot button. This will open the
              Dialogflow workspace, where you'll connect your Dialogflow bot.
            </p>
            <img
              src={select_dialogflow}
              alt="select_dialogflow"
              className="border"
            />
          </div>
          <div className="mb-8">
            <SubTitle
              subtitle="Step - 4 Click on Connect Dialogflow"
              hrefLink="step-4-click-on-connect-dialogflow"
            />
            <p className="mb-2">
              After clicking the Create Bot button. This will open the
              Dialogflow workspace, where you'll connect your Dialogflow bot.
            </p>
            <img
              src={connect_dialogflow}
              alt="connect_dialogflow"
              className="border"
            />
          </div>
          <div className="mb-8">
            <p className="mb-2 text-black font-medium">
              It will open the Dialogflow configuration
            </p>
            <img
              src={configuration_view}
              alt="configuration_view"
              className="border"
            />
          </div>

          <div className="mb-8">
            <SubTitle
              subtitle="Step - 5 Click on Sign in with google in Dialogflow configuration"
              hrefLink="step-5-click-on-sign-in-with-google-in-dialogflow-configuration"
            />
            <p className="mb-2">
              In the Dialogflow configuration window, click on Sign in with
              Google. Choose the Google account you wish to use and sign in.
              After signing in, you will be asked to grant XUnified access to
              your Google Account. Click on Continue to proceed.
            </p>
            <img
              src={configuration_step1}
              alt="configuration_step1"
              className="border"
            />
          </div>
          <div className="mb-8">
            <p className="mb-2 text-black font-medium">Choose an account</p>
            <img src={select_account} alt="select_account" className="border" />
          </div>
          <div className="mb-8">
            <p className="mb-2 text-black font-medium">
              Sign in to XUnified. Click on Continue
            </p>
            <img src={select_account} alt="select_account" className="border" />
          </div>
          <div className="mb-8">
            <p className="mb-2 text-black font-medium">
              XUnified wants access to your Google Account. Click on Continue.
            </p>
            <img src={click_continue} alt="click_continue" className="border" />
          </div>

          <div className="mb-8">
            <SubTitle
              subtitle="Step - 6 Create new project or select existing project in Dialogflow configuration"
              hrefLink="step-6-create-new-project-or-select-existing-project"
            />
            <p className="mb-2">
              Next, you will be prompted to either create a new project or
              select an existing project within Dialogflow. Choose the
              appropriate option and click Continue to move forward.
            </p>
            <img
              src={configuration_step2}
              alt="configuration_step2"
              className="border"
            />
          </div>
          <div className="mb-8">
            <p className="mb-2 text-black font-medium">Click on Continue</p>
            <img
              src={configuration_step2_continue}
              alt="configuration_step2_continue"
              className="border"
            />
          </div>

          <div className="mb-8">
            <SubTitle
              subtitle="Step - 7 Click on Continue with existing agent or update agent in Dialogflow configuration"
              hrefLink="step-7-click-on-continue-with-existing-agent"
            />
            <p className="mb-2">
              Once the project is selected, you will have the option to continue
              with an existing agent or update an agent. Choose the desired
              option and click Continue.
            </p>
            <img
              src={configuration_step2_continue}
              alt="configuration_step2_continue"
              className="border"
            />
          </div>

          <div className="mb-8">
            <SubTitle
              subtitle="Step - 8 Create a new service account or select existing service account in Dialogflow configuration"
              hrefLink="step-8-create-a-new-service-account"
            />
            <p className="mb-2">
              In this step, you’ll need to either create a new service account
              or select an existing one in the Dialogflow configuration. Once
              done, click on Continue.
            </p>
            <img
              src={configuration_step4}
              alt="configuration_step4"
              className="border"
            />
          </div>
          <div className="mb-8">
            <p className="mb-2 text-black font-medium">Click on Continue</p>
            <img
              src={configuration_step4_continue}
              alt="configuration_step4_continue"
              className="border"
            />
          </div>

          <div className="mb-8">
            <SubTitle
              subtitle="Step - 9 Assign the role to selected service account by following the Link in Dialogflow configuration"
              hrefLink="step-9-assign-the-role-to-selected-service-account"
            />
            <p className="mb-2">
              You must assign the correct role to the selected service account
              by following the link provided in the Dialogflow configuration.
              This ensures the necessary permissions are in place for your bot
              to operate correctly.
            </p>
            <img
              src={configuration_step5}
              alt="configuration_step5"
              className="border"
            />
          </div>

          <div className="mb-8">
            <SubTitle
              subtitle="Step - 10 Click on Submit in Dialogflow configuration"
              hrefLink="step-10-click-on-submit-in-dialogflow-configuration"
            />
            <p className="mb-2">
              Finally, after all configurations are in place, click on Submit.
              This action will create and connect your Dialogflow bot to your
              application, completing the setup process.
            </p>
            <img
              src={configuration_step5_submit}
              alt="configuration_step5_submit"
              className="border"
            />
          </div>
          <div className="mb-8">
            <p className="mb-2 text-black font-medium">
              It will create the Dialogflow
            </p>
            <img
              src={dialogflow_successfully}
              alt="dialogflow_successfully"
              className="border"
            />
          </div>

          <div className="flex justify-between">
            <PreviousBtn path="/bot-configuration/api-key" btnText="API Key" />
            <NextBtn path="/bot-configuration/llm" btnText="LLM" />
          </div>
        </div>

        <RightBar rightBarMenu={rightBarMenu} />
      </div>
    </>
  );
}

export default Dialogflow;
