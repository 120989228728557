import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { scrollActivemenu, scrollActivemenuText } from "../appStore/MenuSlice";

function ScrollActive({ rightBarMenu }) {
  const flatArray = (data, responceData = []) => {
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      responceData.push(element.tolink);
      if (element?.children?.length > 0) {
        flatArray(element?.children, responceData);
      }
    }
    return responceData;
  };

  const dispatch = useDispatch();
  const handleScroll = () => {
    let currentSection = "";
    let currentSectionText = "";
    const tempArr = flatArray(rightBarMenu);

    tempArr.forEach((section) => {
      const sectionElement = document.getElementById(section);
      if (sectionElement) {
        const offsetTop = sectionElement.offsetTop;
        const scrollPosition = window.scrollY;
        const largeScreenOffset = window.innerWidth >= 1280 ? 180 : 250;
        const smallScreenOffset = window.innerWidth >= 1280 ? 90 : 155;

        if (
          sectionElement.id === rightBarMenu[0].tolink &&
          scrollPosition >= offsetTop - largeScreenOffset
        ) {
          currentSection = section;
          currentSectionText = sectionElement.innerText;
        } else if (scrollPosition >= offsetTop - smallScreenOffset) {
          currentSection = section;
          currentSectionText = sectionElement.innerText;
        }
      }
    });

    dispatch(scrollActivemenu(currentSection));
    dispatch(scrollActivemenuText(currentSectionText));
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return;
}

export default ScrollActive;
