import { IconChevronRight } from "@tabler/icons-react";
import React from "react";
import { Link } from "react-router-dom";

function NextBtn({ path, btnText }) {
  return (
    <Link
      to={path}
      className="py-2 px-3 rounded-lg hover:bg-gray-200 w-[200px]"
    >
      <div className="flex gap-2 items-center text-indigo-600 text-sm font-medium justify-end">
        <div>
          <p>Next</p>
          <p className="text-gray-600 text-sm font-medium text-end">
            {btnText}
          </p>
        </div>
        <span>
          <IconChevronRight size={18} />
        </span>
      </div>
    </Link>
  );
}

export default NextBtn;
