export const xunifiedKey = [
  {
    keytitle: "Customizable Application",
    keycontent:
      "XUnified's application empowers you to build and customize your customer engagement channels effortlessly. Tailor your channels to fit your unique business requirements and brand identity with easy-to-use interfaces and adaptable design options.",
  },
  {
    keytitle: "Omnichannel Connectivity",
    keycontent:
      "With XUnified's powerful Omnichannel Connectivity feature, you can engage seamlessly with your customers across multiple channels. Whether it's through social media platforms, websites, mobile apps, or messaging services, XUnified ensures that you can reach your audience wherever they are.",
  },
  {
    keytitle: "Bot Configuration",
    keycontent:
      "Automate routine tasks, provide instant responses to common inquiries, and enhance customer self-service capabilities with XUnified's Bot Configuration feature. Customize your bots to match your brand voice and efficiently handle customer interactions, freeing up your team's time for more complex issues.",
  },
  {
    keytitle: "Broadcast Messaging",
    keycontent:
      "Broadcast important messages, promotions, and announcements to your audience across multiple channels simultaneously with XUnified's Broadcast feature. Reach your customers wherever they are, ensuring that your messages are delivered promptly and effectively.",
  },
];

export const dashboardKey = [
  {
    keytitle: "Dashboard Redirection",
    keycontent:
      "Upon signing in, users will be directed to their dashboard, ensuring immediate access to important information and features.",
  },
  {
    keytitle: "Trial and Subscription Details",
    keycontent:
      "Users will be able to view the remaining days of their free trial or subscription, along with the last date of their trial or subscription period, providing transparency and clarity.",
  },
  {
    keytitle: "Key Metrics Display",
    keycontent:
      "The dashboard will display various metrics, including the count of active omnichannels, created applications, Dialogflow instances, knowledgebases, prompts, and completed broadcasts. This enables users to track their activity and performance effectively.",
  },
  {
    keytitle: "Graphical Representation",
    keycontent:
      "Users can visualize user data according to channels through an interactive graph. This graph provides insights into user engagement and behavior across different channels, facilitating informed decision-making.",
  },
  {
    keytitle: "Filter Options",
    keycontent:
      "The graph will feature two filters: the channel filter and the date filter. These filters allow users to customize their view and analyze data based on specific criteria, enhancing flexibility and usability.",
  },
  {
    keytitle: "Circular Message Count Chart",
    keycontent:
      "Additionally, users can view message counts according to channels in a circular chart format. This intuitive visualization offers a quick overview of message distribution across different channels, aiding in data interpretation and analysis.",
  },
];

export const applicationkey = [
  {
    keytitle: "Create Applications Tailored to Your Business",
    keycontent:
      "With XUnified's advanced application creation tools, businesses can bring their vision to life by designing applications that perfectly align with their unique requirements and brand identity. Whether it's a customer support portal, an e-commerce platform, or a service booking system, our intuitive interface and extensive customization options make application development a breeze.",
  },
  {
    keytitle: "Connect Applications with Social Channels and the Web",
    keycontent:
      "Gone are the days of siloed communication channels. XUnified enables businesses to seamlessly integrate their applications with social media platforms, websites, and other online channels. By bridging the gap between different touchpoints, businesses can create a unified customer experience that fosters engagement and loyalty.",
  },
  {
    keytitle: "Configure the Chatbot Experience",
    keycontent:
      "XUnified takes customer service to the next level with its powerful chatbot configuration capabilities. Businesses can fine-tune their chatbots to deliver personalized and efficient support to customers round the clock. From answering frequently asked questions to guiding users through complex processes, our chatbots are designed to enhance customer satisfaction and streamline operations.",
  },
  {
    keytitle: "Customize the Chat Widget",
    keycontent:
      "The chat widget is often the first point of contact between businesses and customers. With XUnified, businesses can configure the chat widget to match their branding guidelines and website aesthetics seamlessly. From colors and fonts to placement and behavior, every aspect of the chat widget can be customized to create a cohesive and engaging user experience.",
  },
];

export const omnichannelkey = [
  {
    keytitle: "Connect to Various Omni-Channels",
    keycontent:
      "With XUnified, businesses can expand their reach beyond traditional communication channelsand connect with customers on popular platforms such as WhatsApp, Messenger, Twilio, Telegram, Instagram, and many more. Whether it's social media, messaging apps, or SMS, XUnified provides the flexibility to engage with customers wherever they are.",
  },
  {
    keytitle: "Unified Messaging Experience",
    keycontent:
      "Gone are the days of managing multiple communication channels separately. XUnified offers a unified messaging experience, allowing businesses to view and respond to customer inquiries from different channels within a single interface. This centralized approach streamlines operations, increases efficiency, and ensures a consistent level of service across all channels.",
  },
  {
    keytitle: "Personalized Customer Interactions",
    keycontent:
      "With XUnified's Omni-Channel Connectivity, businesses can deliver personalized interactions tailored to each customer's preferences and behavior. Whether it's a support request on WhatsApp, a product inquiry on Facebook Messenger, or a promotional message on Instagram, XUnified enables businesses to engage with customers in a meaningful and relevant way.",
  },
  {
    keytitle: "Enhanced Customer Engagement",
    keycontent:
      "By leveraging a diverse range of communication channels, businesses can enhance customer engagement and foster stronger relationships with their audience. Whether it's providing real-time support, sharing product updates, or running targeted marketing campaigns, XUnified empowers businesses to engage with customers on their terms, driving loyalty and satisfaction.",
  },
];

export const botConfigurationkey = [
  {
    keytitle: "Configure Dialogflow and LLM Knowledgebase and Prompt",
    keycontent:
      "XUnified empowers businesses to harness the capabilities of industry-leading Al platforms such as Dialogflow and LLM Knowledgebase and Prompt. User can train the model. With easy-to-use configuration options, users can seamlessly integrate these powerful tools into their support workflows, enabling intelligent and efficient responses to customer inquiries.",
  },
  {
    keytitle: "Automate Routine Tasks",
    keycontent:
      "Say goodbye to repetitive and time-consuming tasks. With XUnified's Bot Configuration feature, businesses can automate routine tasks such as answering frequently asked questions, providing product information, or guiding users through common processes. By offloading these tasks to Al-powered chatbots, businesses can free up valuable time and resources, allowing their teams to focus on more strategic initiatives.",
  },
  {
    keytitle: "Provide Instant Responses",
    keycontent:
      "Customers expect instant gratification when seeking support. With XUnified, businesses can meet these expectations by providing instant responses to common inquiries. Whether it's during business hours or in the middle of the night, our Al-powered chatoots are available 24/7 to provide timely and accurate assistance to users, enhancing the overall customer experience.",
  },
  {
    keytitle: "Enhance Customer Self-Service Capabilities",
    keycontent:
      "Empower your customers to find answers to their questions on their own terms. XUnified's Bot Configuration feature enables businesses to create interactive self-service experiences that guide users through troubleshooting steps, provide relevant information, and offer solutions to common issues. By empowering customers to help themselves, businesses can reduce support ticket volumes and improve overall satisfaction levels.",
  },
  {
    keytitle: "Connect the Bot with Your Application",
    keycontent:
      "Seamlessly integrate the bot with your application to provide a frictionless support experience for your users. Whether it's a web-based application, a mobile app, or a messaging platform, XUnified's Bot Configuration feature allows businesses to connect the bot directlyto their application, ensuring that users can access support whenever and wherever they need it.",
  },
];

export const dialogflowkey = [
  {
    keytitle: "Natural Language Processing (NLP)",
    keycontent:
      "Dialogflow harnesses advanced NLP capabilities to interpret and understand user input, enabling natural and intuitive interactions between users and application",
  },
  {
    keytitle: "Multi-Language Support",
    keycontent:
      "With support for multiple languages, Dialogflow ensures that applications can cater to diverse user demographics, providing a localized and personalized experience.",
  },
  {
    keytitle: "Cross-Platform Compatibility",
    keycontent:
      "Dialogflow enables developers to create applications for various platforms, including websites, mobile devices, messaging platforms (Srtertel enskring fiepred scestbticyand usability.",
  },
  {
    keytitle: "Rich Conversational Experiences",
    keycontent:
      "By leveraging Dialogflow's capabilities, developers can create rich and engaging conversational experiences that mimic human-like interactions, enhancing user satisfaction and engagement.",
  },
];

export const llmkey = [
  {
    keytitle: "Serve as a hub",
    keycontent:
      "LLM serves as a hub where users can explore into the details of Al by crafting knowledge bases or prompts to train models",
  },
  {
    keytitle: "Leveraging advance NLP techniques",
    keycontent:
      "Leveraging advanced NLP techniques, LLM models adeptly grasp user inquiries and furnish articulate responses in a conversational manner.",
  },
  {
    keytitle: "Incorporate Diverse data sources",
    keycontent:
      "Within the realm of Knowledgebase, users wield the power to enrich the model's understanding by incorporating diverse data sources. Whether it's embedding links, inputtingtext, or uploading files, the model undergoes rigorous trainingto comprehensively address user queries.",
  },
];

export const aiStudiokey = [
  {
    keytitle: "No-Code Conversation Design",
    keycontent:
      "Al Studio provides a user-friendly interface for designing virtual assistants, enabling individuals with varying levels of technical expertise to participate in the development process.",
  },
  {
    keytitle: "Text Interaction Solutions",
    keycontent:
      "The platform offers a suite of software solutions that allow businesses to engage with their customers through text-based interactions, revolutionizing customer engagement strategies.",
  },
  {
    keytitle: "Virtual Agent Deployment",
    keycontent:
      "Users can design virtual agents that automate processes and integrate seamlessly with various services, expanding the capabilities of customer support and service delivery.",
  },
  {
    keytitle: "Drag-and-Drop Interface",
    keycontent:
      "Designing conversation flows is made simple with a drag-and-drop interface, allowing users to arrange modules and actions effortlessly.",
  },
  {
    keytitle: "Integration Capabilities",
    keycontent:
      "Virtual agents created in Al Studio can integrate with backend services using webhook actions, enabling seamless communication and data exchange.",
  },
];

export const Campaignkey = [
  {
    keytitle: "Create and Schedule Broadcasts",
    keycontent:
      "XUnified allows users to create and schedule broadcasts with ease. Whether it's a time-sensitive announcement or a planned promotion, users can craft their message and schedule it to be sent at the optimal time for maximum impact. With XUnified, businesses can stay organized and ensure that their messages reach their audience when they're most likely to be engaged.",
  },
  {
    keytitle: "Create and Manage Contacts and Groups",
    keycontent:
      "Efficiently manage your contacts and groups with XUnified's intuitive interface. Users can create and organize contact lists and groups based on various criteria, such as demographics, interests, or engagement level. This enables targeted and personalized communication, ensuring that the right message reaches the right audience every time.",
  },
  {
    keytitle: "Create and Manage Templates",
    keycontent:
      "Save time and maintain consistency with XUnified's template management feature. Users can create and save templates for common broadcast messages, streamlining the creation process and ensuring brand consistency across all communications. With customizable templates, businesses can deliver a cohesive message to their audience with minimal effort.",
  },
  {
    keytitle: "Manage Chat History of the Broadcast",
    keycontent:
      "Track and analyze the effectiveness of your broadcasts with XUnified's comprehensive chat history management tools. You can access detailed records of past broadcasts, including delivery status, engagement metrics, and user responses. This allows you to evaluate the performance of your communications and make informed decisions for future broadcasts.",
  },
];

export const livechatkey = [
  {
    keytitle: "View Chat Conversations",
    keycontent:
      "Live chat enables users to view ongoing conversations, whether it's an individual chator a broadcast. This feature allows for seamless communication and collaboration among participants.",
  },
  {
    keytitle: "Create Messages with Attachments and Emojis",
    keycontent:
      "Users have the flexibility to compose messages according to their needs. They can create messages, attach files such as documents, images, or videos, and add emojis to express emotions or convey additional context. This versatility enriches the communication experience and fosters engagement.",
  },
  {
    keytitle: "Message Delivery Indicators",
    keycontent:
      "Live chat platforms typically include indicatorsto signify message delivery status. Two grayticks usually denote that the message has been successfully delivered to the recipient's device, indicating that it's in transit.",
  },
  {
    keytitle: "Read Receipts",
    keycontent:
      "Blue ticks serve as receipts, indicating that the recipienthas opened and read the message. This feature provides users with real-time visibility into the status of their messages, allowing for better follow-up and responsiveness.",
  },
  {
    keytitle: "Timestamps for Message Tracking",
    keycontent:
      "Timestamps accompany each message, displaying the time when it was sent or received. This feature helps users track the timeline of conversations and ensures timely responses.",
  },
];

export const analyticskey = [
  {
    keytitle: "User Interaction Graphs",
    keycontent:
      "Visualize user interactions with easy-to-understand graphs. Track application user count, channel user count, and bot user count to understand engagement levels",
  },
  {
    keytitle: "Omnichannel Message Count",
    keycontent:
      "Monitor message counts across various channelsin one unified view. Identify which channels are driving the most engagement and adjust your strategy accordingly.",
  },
  {
    keytitle: "User Interaction Analysis",
    keycontent:
      "Dive into detailed metrics on user interactions. Analyzethe distribution and trends of application users, channel users, and bot users to optimize user experience.",
  },
  {
    keytitle: "Messaging Analytics",
    keycontent:
      "Access allmessage data in one place for comprehensive analysis Filter and analyze messagesby date, application, bot, channel, and broadcast to gain detailed insights.",
  },
  {
    keytitle: "Date-Wise Messaging Insights",
    keycontent:
      "Track and analyze message trends over time. Identify peak interactiontimesand plan your communication strategy effectively.",
  },
  {
    keytitle: "Application Message Metrics",
    keycontent:
      "Focus on messages sent and received within your application. Optimize in-app communication to enhance user experience and engagement.",
  },
  {
    keytitle: "Bot Message Analysis",
    keycontent:
      "Gain insights intobot performance by analyzing bot message data. Improve bot interactions based on detailed messaging analytics.",
  },
  {
    keytitle: "Channel Message Overview",
    keycontent:
      "Monitor messages across different channels to understand user preferences. Tailor your communication strategy to each channelfor maximum impact.",
  },
  {
    keytitle: "Broadcast Message Tracking",
    keycontent:
      "Analyze the effectiveness of broadcast messages. Measure reach and engagement to refine your broadcast strategies.",
  },
];

export const contactskey = [
  {
    keytitle: "Create New Contact",
    keycontent:
      "Users can easily add new contacts by entering essential information. This includes fields for first name, last name, email address, phone number. This functionality ensures that all pertinent details are captured accurately, enabling effective communication and follow-up.",
  },
  {
    keytitle: "View Contact Details",
    keycontent:
      "Users can access comprehensive details for each contact. This feature allows users to stay informed and maintain accurate records. Viewing contact details provides insights into the contact's information, ensuring that users have all necessary details at their fingertips.",
  },
  {
    keytitle: "Edit Contact",
    keycontent:
      "The ability to update contact information is crucialfor maintaining a current and relevant contact list. Users can modify any part of a contact's details, such as updating an email address or phone number, ensuring that the contact information remains accurate and up-to-date.",
  },
  {
    keytitle: "Delete Contact",
    keycontent:
      "To maintain a clean and efficient contact database, users can delete contacts that are no longer needed. This feature helps in managing the contact list effectively, ensuring that it only contains relevant and active contacts, thereby avoiding clutter and maintaining organization.",
  },
];

export const logskey = [
  {
    keytitle: "Activity Logs Display",
    keycontent:
      "Users have access to logs detailing all system activities, categorized into various sections such as sign-ins, application management, omnichannel configurations, bot configurations, broadcasts, settings, and more. This comprehensive log ensures transparency and accountability across all platform activities.",
  },
  {
    keytitle: "Table Data Logs",
    keycontent:
      "The logs are presented in a tabular format, showcasing essential details including log type, user, log date & time, and log detail. This organized display allows users to quickly identify and review specific activities, facilitating efficient monitoring and troubleshooting.",
  },
  {
    keytitle: "Filter Options",
    keycontent:
      "Users can customize their log view using filter options located above the log table. Three filter categories are available: time-wise, activity-wise, and a combination of both. Time-wise filters include options for all, custom date range, month, day, and time slots. Activity-wise filters categorize logs based on specific activities such as application management, omnichannel configurations, bot configurations, etc. These filters can be used individually or in combination to refine log searches and analysis.",
  },
  {
    keytitle: "List View Logs",
    keycontent:
      "Logs are presented in a list view format, allowing users to review log details, logtypes, users involved, and timestamps. This structured presentation enhances readability and facilitates quick access to relevant information.",
  },
];

export const settingskey = [
  {
    keytitle: "Profile Management",
    keycontent:
      "Users can effortlessly update their personal information and company profile within the Settings menu. From contact details to organizational information, users have full control over their profile settings, ensuring accurate and up-to-date information.",
  },
  {
    keytitle: "User Invitations",
    keycontent:
      "The Settings menu allows users to invite and manage team members efficiently. By sending invitations, users can onboard new team members and assign relevant roles and permissions, fostering collaboration and teamwork.",
  },
  {
    keytitle: "OpenAl API Key Management",
    keycontent:
      "Users can add and manage OpenAl API keys required for knowledge base and prompt creation. With easy access to API key management, users can streamline the integration process and leverage Al capabilities seamlessly.",
  },
  {
    keytitle: "Subscription Details",
    keycontent:
      "In the Subscription section, users can access comprehensive details regarding their subscription plan. From viewing subscription status to managing payment methods, users can conveniently handle subscription-related tasks within the Settings menu.",
  },
];

export const docskey = [
  {
    keytitle: "Platform Documentation",
    keycontent:
      "Docs offers extensive documentation covering every aspect of our platform, from basic functionalities to advanced features. Users can access detailed information on various components, modules, and tools, gaining a deeper understanding of how to leverage the platform effectively.",
  },
  {
    keytitle: "Quick Start Guides",
    keycontent:
      "For new users, Docs provides quick start guides that offer step-by-step instructions on how to set up and get started with the platform. These guides streamline the onboarding process, enabling users to familiarize themselves with key features and functionalities quickly.",
  },
  {
    keytitle: "Feature Introduction",
    keycontent:
      "Explore detailed introductions to each feature available on the platform. Whether it's chatbots, analytics, integrations, or automation tools, Docs provides comprehensive overviews of each feature, highlighting their benefits and use cases.",
  },
  {
    keytitle: "Configuration Steps",
    keycontent:
      "Docs offers clear and concise configuration steps for implementing various features and functionalities. Users can follow these step-by-step guides to configure settings, customize options, and optimize their platform according to their specific requirements.",
  },
  {
    keytitle: "How It Works",
    keycontent:
      "Gain insights into how each feature works and understand its underlying mechanisms. Docs provides in-depth explanations and visual representations to illustrate the functionality of different features, helping users grasp concepts and workflows more effectively.",
  },
];

export const supportskey = [
  {
    keytitle: "Issue Ticketing",
    keycontent:
      "Users can easily raise tickets to report any concerns or problems they encounter while using the platform. Whether it's a technical issue, a bug, or a question about functionality, users can submit tickets to seek our support team's assistance.",
  },
  {
    keytitle: "Feature Request Ticketing",
    keycontent:
      "Users can submit feature request tickets to propose new functionalities or enhancements to existing features. Our supportteam evaluates these requests and provides updates on their progress, ensuring that user feedback is heard and considered in the platform's development roadmap.",
  },
  {
    keytitle: "Ticket Progress Tracking",
    keycontent:
      "Once a ticket is raised, users can track its progress and status in real-time. From submission to resolution, users have visibility into the ticket lifecycle, allowing them to stay informed about the resolution process and expected timelines.",
  },
  {
    keytitle: "Live Chat Support",
    keycontent:
      "For immediate assistance, users can engage in live chat sessions with our support personnel. Whether it's troubleshooting assistance or guidance on platform usage, users can interact with support representatives in real-time to address their queries and concerns.",
  },
  {
    keytitle: "Seamless Communication",
    keycontent:
      "Support facilitates seamless communication between users and our supportteam, ensuring that issues are promptly addressed, and queries are efficiently resolved. Through ticketing and live chat features, users can engage with support personnel and receive timely assistance tailored to their needs.",
  },
];
