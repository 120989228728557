import React from "react";
import Title from "../../components/Title";
import PreviousBtn from "../../components/PreviousBtn";
import NextBtn from "../../components/NextBtn";
import logs from "../../images/logs.gif";

function Logs() {
  return (
    <>
      <div className="lg:flex gap-6 sm:p-8 p-5 text-gray-600">
        <div className="flex-1">
          <Title title="Logs" />
          <p className="mb-5">
            The Logs section is a comprehensive tool for monitoring and
            reviewing all activities related to your system. It provides
            detailed logs for various components, including:
          </p>
          <ul className="mb-5 pl-5 space-y-2">
            <li className="list-disc">
              <span className="text-black font-medium">Application:</span> Track
              and review activities and events specific to your applications.
            </li>
            <li className="list-disc">
              <span className="text-black font-medium">Omni Channel:</span>{" "}
              Monitor interactions and activities across different communication
              channels.
            </li>
            <li className="list-disc">
              <span className="text-black font-medium">Bot Configuration:</span>{" "}
              Access logs related to bot setups and configurations.
            </li>
            <li className="list-disc">
              <span className="text-black font-medium">Broadcast:</span> View
              logs of broadcast messages and related activities.
            </li>
            <li className="list-disc">
              <span className="text-black font-medium">Settings:</span> Examine
              changes and updates made to system settings.
            </li>
          </ul>
          <p className="mb-5">
            You can filter log data based on specific components and select a
            desired date range to focus on relevant periods. Additionally, logs
            can be viewed in both chart and tabular formats, offering
            flexibility in how you analyze and interpret the data.
          </p>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 1 Navigate to Logs. It will open Logs workspace
            </p>
            <p>
              Begin by accessing the Logs menu. This will open the Logs
              workspace where you can view all available logs.
            </p>
          </div>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 2 Select the filter option
            </p>
            <p>
              Click on the Filter option to specify the criteria for the logs
              you want to view.
            </p>
          </div>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 3 Select the Date Range
            </p>
            <p>
              Choose the desired Date Range to filter the log data according to
              the specified time period.
            </p>
          </div>
          <img src={logs} alt="logs" className="border mb-10" />

          <div className="flex justify-between">
            <PreviousBtn path="/contacts" btnText="Contacts" />
            <NextBtn path="/settings" btnText="Settings" />
          </div>
        </div>
      </div>
    </>
  );
}

export default Logs;
