import React from "react";
import ScrollActive from "../../components/ScrollActive";
import Topbar from "../../components/Topbar";
import Title from "../../components/Title";
import SubTitle from "../../components/SubTitle";
import PreviousBtn from "../../components/PreviousBtn";
import NextBtn from "../../components/NextBtn";
import RightBar from "../../components/RightBar";
import wsa1 from "../../images/omnichannelimg/wsa1.jpg";
import wsa2 from "../../images/omnichannelimg/wsa2.jpg";
import wsa3 from "../../images/omnichannelimg/wsa3.jpg";
import wsa4 from "../../images/omnichannelimg/wsa4.jpg";
import wsa5 from "../../images/omnichannelimg/wsa5.jpg";
import continue_as from "../../images/omnichannelimg/whatsAppintegrationimg/continue_as.jpg";
import get_started from "../../images/omnichannelimg/whatsAppintegrationimg/get_started.jpg";
import fillbusiness_information from "../../images/omnichannelimg/whatsAppintegrationimg/fill_business_information.jpg";
import create_a_business_portfolio from "../../images/omnichannelimg/whatsAppintegrationimg/create_business_portfolio.jpg";
import add_business_name from "../../images/omnichannelimg/whatsAppintegrationimg/add_business_name.jpg";
import add_business_email from "../../images/omnichannelimg/whatsAppintegrationimg/add_business_email.jpg";
import add_business_profile_page from "../../images/omnichannelimg/whatsAppintegrationimg/add_business_profile_page.jpg";
import select_Country from "../../images/omnichannelimg/whatsAppintegrationimg/select_Country.jpg";
import click_Next8 from "../../images/omnichannelimg/whatsAppintegrationimg/click_Next8.jpg";
import create_select_WhatsAppBuAcc from "../../images/omnichannelimg/whatsAppintegrationimg/create_select_WhatsAppBuAcc.jpg";
import create_WhatsAppBuAcc from "../../images/omnichannelimg/whatsAppintegrationimg/create_WhatsAppBuAcc.jpg";
import create_select_w_profile from "../../images/omnichannelimg/whatsAppintegrationimg/create_select_w_profile.jpg";
import click_Next9 from "../../images/omnichannelimg/whatsAppintegrationimg/click_Next9.jpg";
import create_wha_Profile from "../../images/omnichannelimg/whatsAppintegrationimg/create_wha_Profile.jpg";
import add_business_account_name from "../../images/omnichannelimg/whatsAppintegrationimg/add_business_account_name.jpg";
import add_whatsApp_display_name from "../../images/omnichannelimg/whatsAppintegrationimg/add_whatsApp_display_name.jpg";
import select_category from "../../images/omnichannelimg/whatsAppintegrationimg/select_category.jpg";
import click_Next10 from "../../images/omnichannelimg/whatsAppintegrationimg/click_Next10.jpg";
import add_phone_number_whatsApp from "../../images/omnichannelimg/whatsAppintegrationimg/add_phone_number_whatsApp.jpg";
import add_phone_number from "../../images/omnichannelimg/whatsAppintegrationimg/add_phone_number.jpg";
import choose_verification_method from "../../images/omnichannelimg/whatsAppintegrationimg/choose_verification_method.jpg";
import click_next11 from "../../images/omnichannelimg/whatsAppintegrationimg/click_next11.jpg";
import verify_phone_number from "../../images/omnichannelimg/whatsAppintegrationimg/verify_phone_number.jpg";
import click_next12 from "../../images/omnichannelimg/whatsAppintegrationimg/click_next12.jpg";
import click_continue from "../../images/omnichannelimg/whatsAppintegrationimg/click_continue.jpg";
import click_finish from "../../images/omnichannelimg/whatsAppintegrationimg/click_finish.jpg";
import configuring_whatsAPP from "../../images/omnichannelimg/whatsAppintegrationimg/configuring_whatsAPP.jpg";
import select_phone_number_continue from "../../images/omnichannelimg/whatsAppintegrationimg/select_phone_number_continue.jpg";
import click_save13 from "../../images/omnichannelimg/whatsAppintegrationimg/click_save13.jpg";
import whatsApp_configured_successfully from "../../images/omnichannelimg/whatsAppintegrationimg/whatsApp_configured_successfully.jpg";
import navigate_application from "../../images/omnichannelimg/whatsAppintegrationimg/navigate_application.jpg";
import click_existing_application from "../../images/omnichannelimg/whatsAppintegrationimg/click_existing_application.jpg";
import select_Bot from "../../images/omnichannelimg/whatsAppintegrationimg/select_Bot.jpg";
import connect_Bot from "../../images/omnichannelimg/whatsAppintegrationimg/connect_Bot.jpg";
import connect_WhatsApp from "../../images/omnichannelimg/whatsAppintegrationimg/connect_WhatsApp.jpg";
import send_WhatsApp_msg from "../../images/omnichannelimg/whatsAppintegrationimg/send_WhatsApp_msg.jpg";
import navigate_live_chat from "../../images/omnichannelimg/whatsAppintegrationimg/navigate_live_chat.jpg";
import open_chat from "../../images/omnichannelimg/whatsAppintegrationimg/open_chat.jpg";

const rightBarMenu = [
  {
    tolink: "step-1-navigate-to-omni-channel",
    submenutext: "Step - 1 Navigate to Omni Channel",
  },
  {
    tolink: "step-2-navigate-to-whatsapp",
    submenutext: "Step - 2 Navigate to WhatsApp",
  },
  {
    tolink: "step-3-click-connect",
    submenutext: "Step - 3 Click Connect",
  },
  {
    tolink: "step-4-click-continue-with-meta",
    submenutext: "Step - 4 Click Continue with Meta",
  },
  {
    tolink: "step-5-login-to-facebook",
    submenutext: "Step - 5 Login to Facebook",
  },
  {
    tolink: "step-6-click-continue-as-account-name",
    submenutext: 'Step - 6 Click Continue as "Account name"',
  },
  {
    tolink: "step-7-click-on-get-started",
    submenutext: "Step - 7 Click on Get started",
  },
  {
    tolink: "step-8-fill-in-your-business-information",
    submenutext: "Step - 8 Fill in your business information",
  },
  {
    tolink: "step-9-create-or-select-your-whatsapp-business-account",
    submenutext: "Step - 9 Create or select your WhatsApp Business Account",
  },
  {
    tolink: "step-10-create-a-whatsapp-business-profile",
    submenutext: "Step - 10 Create a WhatsApp Business Profile",
  },
  {
    tolink: "step-11-add-a-phone-number-for-whatsapp",
    submenutext: "Step - 11 Add a phone number for WhatsApp",
  },
  {
    tolink: "step-12-verify-your-phone-number",
    submenutext: "Step - 12 Verify your phone number",
  },
  {
    tolink: "step-13-configuring-whatsapp",
    submenutext: "Step - 13 Configuring WhatsApp",
  },
  {
    tolink: "step-14-navigate-to-application",
    submenutext: "Step - 14 Navigate to Application",
  },
  {
    tolink: "step-15-click-on-existing-application",
    submenutext: "Step - 15 Click on Existing Application",
  },
  {
    tolink: "step-16-select-bot",
    submenutext: "Step - 16 Select Bot",
  },
  {
    tolink: "step-17-connect-bot",
    submenutext: "Step - 17 Connect Bot",
  },
  {
    tolink: "step-18-connect-whatsApp",
    submenutext: "Step - 18 Connect WhatsApp",
  },
  {
    tolink: "step-19-send-whatsapp-message",
    submenutext: "Step - 19 Send WhatsApp message",
  },
  {
    tolink: "step-20-navigate-to-live-chat",
    submenutext: "Step - 20 Navigate to Live Chat",
  },
  {
    tolink: "Step - 21 Open the Chat",
    submenutext: "Step - 21 Open the Chat",
  },
];

function WhatsAppIntegration() {
  return (
    <>
      <ScrollActive rightBarMenu={rightBarMenu} />
      <Topbar rightBarMenu={rightBarMenu} />

      <div className="lg:flex gap-6 sm:p-8 p-5 text-gray-600">
        <div className="flex-1">
          <Title title="WhatsApp Integration" />

          <p className="mb-2 text-lg text-black font-medium">
            Before You Start
          </p>
          <p className="mb-5">
            To begin the WhatsApp Integration, you first need to have Facebook
            Account.
          </p>
          <p className="mb-5">
            Set up a Business Manager account or access your existing Meta
            Business Manager.
          </p>
          <p className="mb-5">
            Ensure you have access to a phone number that meets WhatsApp
            Business requirements.
          </p>
          <p className="mb-5">
            Choose a WhatsApp display name that adheres to WhatsApp guidelines.
          </p>

          <div className="mb-8">
            <SubTitle
              subtitle="Step - 1 Navigate to Omni Channel"
              hrefLink="step-1-navigate-to-omni-channel"
            />
            <p className="mb-2">
              Access the Omni Channel section in the XUnified dashboard.
            </p>
            <img src={wsa1} alt="wsa1" className="border" />
          </div>
          <div className="mb-8">
            <SubTitle
              subtitle="Step - 2 Navigate to WhatsApp"
              hrefLink="step-2-navigate-to-whatsapp"
            />
            <p className="mb-2">
              Locate and select the WhatsApp option within the omni channel
            </p>
            <img src={wsa2} alt="wsa1" className="border" />
          </div>
          <div className="mb-8">
            <SubTitle
              subtitle="Step - 3 Click Connect"
              hrefLink="step-3-click-connect"
            />
            <p className="mb-2">
              Initiate the connection process by clicking on the Connect button
            </p>
            <img src={wsa3} alt="wsa1" className="border" />
          </div>
          <div className="mb-8">
            <SubTitle
              subtitle="Step - 4 Click Continue with Meta, in Set up WhatsApp with
              XUnified pop-up"
              hrefLink="step-4-click-continue-with-meta"
            />
            <p className="mb-2">
              Proceed with setting up WhatsApp by selecting "Continue with Meta"
              in the pop-up window.
            </p>
            <img src={wsa4} alt="wsa1" className="border" />
          </div>
          <div className="mb-8">
            <SubTitle
              subtitle="Step - 5 Login to Facebook"
              hrefLink="step-5-login-to-facebook"
            />
            <p className="mb-2">Log in to your Facebook account</p>
            <img src={wsa5} alt="wsa1" className="border" />
          </div>
          <div className="mb-8">
            <SubTitle
              subtitle='Step - 6 Click Continue as "Account name"'
              hrefLink="step-6-click-continue-as-account-name"
            />
            <p className="mb-2">
              Proceed with your Facebook login by clicking "Continue as [Your
              Account Name]".
            </p>
            <img src={continue_as} alt="Continue as" className="border" />
          </div>
          <div className="mb-8">
            <SubTitle
              subtitle="Step - 7 Click on Get started"
              hrefLink="step-7-click-on-get-started"
            />
            <p className="mb-2">
              Begin the setup process by clicking on "Get Started"
            </p>
            <img src={get_started} alt="get started" className="border" />
          </div>

          <div className="mb-8">
            <SubTitle
              subtitle="Step - 8 Fill in your business information"
              hrefLink="step-8-fill-in-your-business-information"
            />
            <p className="mb-2">
              Provide essential business details such as name, email,
              website/profile page, and country.
            </p>
            <p className="mb-2">
              The flow will always display the default legal name as the
              business name. Additionally, it will continue to show the business
              information regardless of whether all required fields are
              completed.
            </p>
            <img
              src={fillbusiness_information}
              alt="fillbusinessinformation"
              className="border"
            />
          </div>
          <div className="mb-8">
            <p className="mb-2 text-black font-medium">
              Create a business portfolio.
            </p>
            <img
              src={create_a_business_portfolio}
              alt="create_a_business_portfolio"
              className="border"
            />
          </div>
          <div className="mb-8">
            <p className="mb-2 text-black font-medium">Business Name</p>
            <img
              src={add_business_name}
              alt="add_business_name"
              className="border"
            />
          </div>
          <div className="mb-8">
            <p className="mb-2 text-black font-medium">Business Email</p>
            <img
              src={add_business_email}
              alt="add_business_email"
              className="border"
            />
          </div>
          <div className="mb-8">
            <p className="mb-2 text-black font-medium">
              Business website or profile page
            </p>
            <img
              src={add_business_profile_page}
              alt="add_business_profile_page"
              className="border"
            />
          </div>
          <div className="mb-8">
            <p className="mb-2 text-black font-medium">Select Country</p>
            <img src={select_Country} alt="select_Country" className="border" />
          </div>
          <div className="mb-8">
            <p className="mb-2 text-black font-medium">Click Next</p>
            <img src={click_Next8} alt="click_Next8" className="border" />
          </div>
          <SubTitle
            subtitle="Step - 9 Create or select your WhatsApp Business Account"
            hrefLink="step-9-create-or-select-your-whatsapp-business-account"
          />
          <p className="mb-2">
            Set up a new WhatsApp Business Account or select an existing one.
          </p>
          <p className="mb-2">
            The flow is standardized, ensuring consistency whether a user is
            adding or selecting a new WABA/phone profile. From this screen,
            users can perform the following actions:
          </p>
          <p className="text-black font-medium">
            1. Create both a new WABA and profile
          </p>
          <p className="mb-2">
            If the user elects to create a new WABA and profile, they will fill
            out all their WABA and profile information on a single page.
          </p>
          <p className="text-black font-medium">
            2. Select an existing WABA and create a new profile
          </p>
          <p className="mb-2">
            If the user selects an existing WABA, they can only edit the phone
            profile field.
          </p>
          <p className="text-black font-medium">
            3. Select an existing WABA and profile
          </p>
          <p className="mb-2">
            If the user selects an existing WABA and existing profile, the
            profile is displayed but will not be editable. To edit the profile,
            it must be done in the WhatsApp Manager.
          </p>
          <div className="mb-8">
            <p className="text-black font-medium mb-2">
              Regardless of the option chosen, the flow will always begin in the
              same manner.
            </p>
            <img
              src={create_select_WhatsAppBuAcc}
              alt="create_select_WhatsAppBuAcc"
              className="border"
            />
          </div>

          <div className="mb-8">
            <p className="mb-2 text-black font-medium">
              Create a WhatsApp Business Account
            </p>
            <img
              src={create_WhatsAppBuAcc}
              alt="create_WhatsAppBuAcc"
              className="border"
            />
          </div>
          <div className="mb-8">
            <p className="mb-2 text-black font-medium">
              Create or Select a WhatsApp Business Profile
            </p>
            <img
              src={create_select_w_profile}
              alt="create_select_w_profile"
              className="border"
            />
          </div>
          <div className="mb-8">
            <p className="mb-2 text-black font-medium">Click Next</p>
            <img src={click_Next9} alt="click_Next9" className="border" />
          </div>

          <SubTitle
            subtitle="Step - 10 Create a WhatsApp Business Profile"
            hrefLink="step-10-create-a-whatsapp-business-profile"
          />
          <p className="mb-2">
            Fill in details for your WhatsApp Business Profile, including the
            account name, display name, and category.
          </p>
          <div className="mb-8">
            <img
              src={create_wha_Profile}
              alt="create_wha_Profile"
              className="border"
            />
          </div>
          <div className="mb-8">
            <p className="mb-2 text-black font-medium">
              Add WhatsApp Business Account Name
            </p>
            <img
              src={add_business_account_name}
              alt="add_business_account_name"
              className="border"
            />
          </div>
          <div className="mb-8">
            <p className="mb-2 text-black font-medium">
              Add WhatsApp Business display name
            </p>
            <img
              src={add_whatsApp_display_name}
              alt="add_whatsApp_display_name"
              className="border"
            />
          </div>
          <div className="mb-8">
            <p className="mb-2 text-black font-medium">
              Select Category from drop-down
            </p>
            <img
              src={select_category}
              alt="select_category"
              className="border"
            />
          </div>
          <div className="mb-8">
            <p className="mb-2 text-black font-medium">Click Next</p>
            <img src={click_Next10} alt="click_Next10" className="border" />
          </div>

          <SubTitle
            subtitle="Step - 11 Add a phone number for WhatsApp"
            hrefLink="step-11-add-a-phone-number-for-whatsapp"
          />
          <p className="mb-2">
            Add a phone number for WhatsApp and choose your preferred
            verification method.
          </p>
          <div className="mb-8">
            <img
              src={add_phone_number_whatsApp}
              alt="add_phone_number_whatsApp"
              className="border"
            />
          </div>
          <div className="mb-8">
            <p className="mb-2 text-black font-medium">Add Phone numbe</p>
            <img
              src={add_phone_number}
              alt="add_phone_number"
              className="border"
            />
          </div>
          <div className="mb-8">
            <p className="mb-2 text-black font-medium">
              Choose how you would like to verify your number
            </p>
            <img
              src={choose_verification_method}
              alt="choose_verification_method"
              className="border"
            />
          </div>
          <div className="mb-8">
            <p className="mb-2 text-black font-medium">Click Next</p>
            <img src={click_next11} alt="click_next11" className="border" />
          </div>

          <SubTitle
            subtitle="Step - 12 Verify your phone number"
            hrefLink="step-12-verify-your-phone-number"
          />
          <p className="mb-2">Verify the provided phone number for WhatsApp.</p>
          <p className="mb-2">
            If the user selects a verified phone number, they will be directed
            to the completion step.
          </p>
          <div className="mb-8">
            <img
              src={verify_phone_number}
              alt="verify_phone_number"
              className="border"
            />
          </div>
          <div className="mb-8">
            <p className="mb-2 text-black font-medium">Click Next</p>
            <img src={click_next12} alt="click_next12" className="border" />
          </div>
          <div className="mb-8">
            <p className="mb-2 text-black font-medium">Click Continue</p>
            <img src={click_continue} alt="click_continue" className="border" />
          </div>
          <div className="mb-8">
            <p className="mb-2 text-black font-medium">Click Finish</p>
            <img src={click_finish} alt="click_finish" className="border" />
          </div>

          <SubTitle
            subtitle="Step - 13 Configuring WhatsApp"
            hrefLink="step-13-configuring-whatsapp"
          />
          <p className="mb-2">
            Finalize the configuration by selecting the phone number and saving
            the settings.
          </p>
          <div className="mb-8">
            <img
              src={configuring_whatsAPP}
              alt="configuring_whatsAPP"
              className="border"
            />
          </div>
          <div className="mb-8">
            <p className="mb-2 text-black font-medium">
              Select Phone Number and Click Continue
            </p>
            <img
              src={select_phone_number_continue}
              alt="select_phone_number_continue"
              className="border"
            />
          </div>
          <div className="mb-8">
            <p className="mb-2 text-black font-medium">Click Save</p>
            <img src={click_save13} alt="click_save13" className="border" />
          </div>
          <div className="mb-8">
            <p className="mb-2 text-black font-medium">
              WhatsApp Configure Successfully
            </p>
            <img
              src={whatsApp_configured_successfully}
              alt="whatsApp_configured_successfully"
              className="border"
            />
          </div>

          <div className="mb-8">
            <SubTitle
              subtitle="Step - 14 Navigate to Application"
              hrefLink="step-14-navigate-to-application"
            />
            <p className="mb-2">
              Go back to the Application section in the dashboard.
            </p>
            <img
              src={navigate_application}
              alt="navigate_application"
              className="border"
            />
          </div>
          <div className="mb-8">
            <SubTitle
              subtitle="Step - 15 Click on Existing Application, it will move to
              application detail"
              hrefLink="step-15-click-on-existing-application"
            />
            <p className="mb-2">
              Select an existing application or create a new one if needed.
            </p>
            <img
              src={click_existing_application}
              alt="click_existing_application"
              className="border"
            />
          </div>
          <div className="mb-8">
            <SubTitle
              subtitle="Step - 16 Select Bot"
              hrefLink="step-16-select-bot"
            />
            <p className="mb-2">
              Choose the Bot option within the selected application.
            </p>
            <img src={select_Bot} alt="select_Bot" className="border" />
          </div>
          <div className="mb-8">
            <SubTitle
              subtitle="Step - 17 Connect Bot"
              hrefLink="step-17-connect-bot"
            />
            <p className="mb-2">
              Establish a connection between your Bot and the selected
              application.
            </p>
            <img src={connect_Bot} alt="connect_Bot" className="border" />
          </div>
          <div className="mb-8">
            <SubTitle
              subtitle="Step - 18 Connect WhatsApp"
              hrefLink="step-18-connect-whatsApp"
            />
            <p className="mb-2">Connect WhatsApp to your Bot.</p>
            <img
              src={connect_WhatsApp}
              alt="connect_WhatsApp"
              className="border"
            />
          </div>
          <div className="mb-8">
            <SubTitle
              subtitle="Step - 19 Send WhatsApp message on configured WhatsApp number"
              hrefLink="step-19-send-whatsapp-message"
            />
            <p className="mb-2">
              Test the setup by sending a message to the configured WhatsApp
              number.
            </p>
            <img
              src={send_WhatsApp_msg}
              alt="send_WhatsApp_msg"
              className="border"
            />
          </div>
          <div className="mb-8">
            <SubTitle
              subtitle="Step - 20 Navigate to Live Chat"
              hrefLink="step-20-navigate-to-live-chat"
            />
            <p className="mb-2">Move to the Live Chat section.</p>
            <img
              src={navigate_live_chat}
              alt="navigate_live_chat"
              className="border"
            />
          </div>
          <div className="mb-8">
            <SubTitle
              subtitle="Step - 21 Open the Chat"
              hrefLink="Step - 21 Open the Chat"
            />
            <p className="mb-2">
              Begin engaging with customers via WhatsApp by opening the chat
              window.
            </p>
            <img src={open_chat} alt="open_chat" className="border" />
          </div>

          <div className="flex justify-between">
            <PreviousBtn path="/applications" btnText="Applications" />
            <NextBtn
              path="/omnichannel/messenger-integration"
              btnText="Messenger Integration"
            />
          </div>
        </div>

        <RightBar rightBarMenu={rightBarMenu} />
      </div>
    </>
  );
}

export default WhatsAppIntegration;
