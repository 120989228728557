import React from "react";
import ScrollActive from "../../components/ScrollActive";
import Topbar from "../../components/Topbar";
import Title from "../../components/Title";
import SubTitle from "../../components/SubTitle";
import PreviousBtn from "../../components/PreviousBtn";
import NextBtn from "../../components/NextBtn";
import RightBar from "../../components/RightBar";
import interacting_with_users from "../../images/analyticsimg/interacting_with_users.gif";
import messages from "../../images/analyticsimg/messages.gif";
import application_count from "../../images/analyticsimg/application_count.gif";
import channel_count from "../../images/analyticsimg/channel_count.gif";
import bot_count from "../../images/analyticsimg/bot_count.gif";
import allmessages from "../../images/analyticsimg/allmessages.gif";
import date_wise_messages from "../../images/analyticsimg/date_wise_messages.gif";
import application_messages from "../../images/analyticsimg/application_messages.gif";
import bot_messages from "../../images/analyticsimg/bot_messages.gif";
import channel_messages from "../../images/analyticsimg/channel_messages.gif";
import broadcast_messages from "../../images/analyticsimg/broadcast_messages.gif";

const rightBarMenu = [
  {
    tolink: "interacting-with-users",
    submenutext: "Interacting with Users",
  },
  {
    tolink: "messages",
    submenutext: "Messages",
  },
  {
    tolink: "user-interaction",
    submenutext: "User Interaction",
    children: [
      {
        tolink: "application-user-count",
        submenutext: "Application User count",
      },
      {
        tolink: "channel-user-count",
        submenutext: "Channel User count",
      },
      {
        tolink: "bot-user-count",
        submenutext: "Bot User count",
      },
    ],
  },
  {
    tolink: "messaging-analytics",
    submenutext: "Messaging Analytics",
    children: [
      {
        tolink: "all-messaging",
        submenutext: "All Messaging",
      },
      {
        tolink: "date-wish-messaging",
        submenutext: "Date Wish Messaging",
      },
      {
        tolink: "application-messaging",
        submenutext: "Application Messaging",
      },
      {
        tolink: "bot-messaging",
        submenutext: "Bot Messaging",
      },
      {
        tolink: "channel-messaging",
        submenutext: "Channel Messaging",
      },
      {
        tolink: "broadcast-messaging",
        submenutext: "Broadcast Messaging",
      },
    ],
  },
];

function Analytics() {
  return (
    <>
      <ScrollActive rightBarMenu={rightBarMenu} />
      <Topbar rightBarMenu={rightBarMenu} />

      <div className="lg:flex gap-6 sm:p-8 p-5 text-gray-600">
        <div className="flex-1">
          <Title title="Analytics" />
          <p className="mb-5">
            Welcome to the Analytics Dashboard, where you can gain valuable
            insights into your platform's performance. This section provides
            detailed analysis across various aspects:
          </p>
          <ul className="mb-5 pl-5 space-y-2">
            <li className="list-disc">
              <span className="text-black font-medium">
                Interacting with Users:
              </span>{" "}
              Understand how users are interacting with the platform, providing
              a clearer picture of user behavior and engagement.
            </li>
            <li className="list-disc">
              <span className="text-black font-medium">Messages:</span> Dive
              into the specifics of message exchanges to optimize communication
              strategies.
            </li>
            <li className="list-disc">
              <span className="text-black font-medium">User Interaction:</span>{" "}
              Monitor how users engage with your application through metrics
              like Application User Count, Channel User Count, and Bot User
              Count.
            </li>
            <li className="list-disc">
              <span className="text-black font-medium">
                Messaging Analytics:
              </span>{" "}
              Track and analyze messaging patterns, including All Messages,
              Date-Wise Messages, and messages sent through Applications, Bots,
              Channels, and Broadcasts.
            </li>
          </ul>
          <p className="mb-5">
            Explore these analytics to make data-driven decisions and enhance
            the overall user experience.{" "}
          </p>
          <SubTitle
            subtitle="Interacting with Users"
            hrefLink="interacting-with-users"
          />
          <p className="mb-5">
            In the Interacting with Users section, you can visualize user
            engagement with the system through interactive charts. By selecting
            a specific date range, you'll see how many users interacted with the
            platform over that period. For further analysis or reporting, you
            can easily export this data in PDF or Excel formats.
          </p>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 1 Navigate to Analytics
            </p>
            <p>
              Begin by accessing the Analytics menu from the main dashboard.
            </p>
          </div>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 2 Navigate to Interacting with users
            </p>
            <p>
              Within Analytics, select Interacting with Users to view user
              engagement data.
            </p>
          </div>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 3 Select the dats for filter the data
            </p>
            <p>
              Once the data is filtered, click on the export option to download
              the data in either PDF or Excel format.
            </p>
          </div>
          <img
            src={interacting_with_users}
            alt="interacting_with_users"
            className="border mb-10"
          />
          <SubTitle subtitle="Messages" hrefLink="messages" />
          <p className="mb-5">
            n the Messages section, you can explore detailed insights into
            message interactions across all omnichannel platforms. By selecting
            a specific date range, you'll be able to view the total message
            count and a circular, color-coded chart that visually represents how
            messages were distributed and interacted with across different
            omnichannel platforms.
          </p>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 1 Navigate to Analytics
            </p>
            <p>
              Start by accessing the Analytics menu from the main dashboard.
            </p>
          </div>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 2 Navigate to Messages
            </p>
            <p>
              Within Analytics, select Messages to explore message interactions
              across omnichannel platforms.
            </p>
          </div>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 3 Select the dates for filter the data
            </p>
            <p>
              Choose the desired date range to filter the data and view the
              corresponding message counts and chart.
            </p>
          </div>
          <img src={messages} alt="messages" className="border mb-10" />

          <SubTitle subtitle="User Interaction" hrefLink="user-interaction" />
          <p className="mb-5">
            In the User Interaction section, you can analyze user engagement
            through a detailed, color-coded chart. This chart provides insights
            into Application User Count, Channel User Count, and Bot User Count.
            You can filter this data by selecting specific date ranges and use
            sorting options to customize your view, allowing for a more targeted
            analysis of user activity across different platforms.
          </p>

          <SubTitle
            subtitle="Application User Count"
            hrefLink="application-user-count"
          />
          <p className="mb-5">
            In the Application User Count section, you can track and analyze the
            number of users interacting with different applications within your
            system. By selecting specific date ranges and applying sorting
            options, you can easily view and compare user counts across various
            applications to understand usage trends and engagement levels.{" "}
          </p>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 1 Navigate to Analytics
            </p>
            <p>
              Begin by accessing the Analytics menu from the main dashboard.
            </p>
          </div>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 2 Navigate to User Interaction
            </p>
            <p>
              Within Analytics, select User Interaction to explore user
              engagement data
            </p>
          </div>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 3 Navigate to Application User Count
            </p>
            <p>
              In the User Interation Section, click on Application User Count to
              view User Activity across different appications.
            </p>
          </div>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 4 Sort the Data
            </p>
            <p>
              Use the sorting options to organize the data according to your
              preferences.
            </p>
          </div>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 5 Select the dates for filter the data
            </p>
            <p>
              Choose the desird data range to filter the data and display
              relevant user counts.
            </p>
          </div>
          <img
            src={application_count}
            alt="application_count"
            className="border mb-10"
          />

          <SubTitle
            subtitle="Channel User count"
            hrefLink="channel-user-count"
          />
          <p className="mb-5">
            In the Channel User Count section, you can track the number of users
            interacting through different communication channels within your
            system. By selecting specific date ranges and using sorting options,
            you can easily view and compare user counts across various channels
            to understand engagement trends and channel performance.
          </p>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 1 Navigate to Analytics
            </p>
            <p>
              Start by accessing the Analytics menu from the main dashboard.
            </p>
          </div>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 2 Navigate to User Interaction
            </p>
            <p>
              Within Analytics, select User Interaction to explore user
              engagement data.
            </p>
          </div>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 3 Navigate to Channel User Count
            </p>
            <p>
              In the User Interaction section, click on Channel User Count to
              view user activity across different communication channels.
            </p>
          </div>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 4 Sort the Data
            </p>
            <p>
              Use the sorting options to organize the data according to your
              preferences.
            </p>
          </div>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 5 Select the dates for filter the data
            </p>
            <p>
              Choose the desired date range to filter the data and display
              relevant user counts.
            </p>
          </div>
          <img
            src={channel_count}
            alt="channel_count"
            className="border mb-10"
          />

          <SubTitle subtitle="Bot User count" hrefLink="bot-user-count" />
          <p className="mb-5">
            In the Bot User Count section, you can analyze user interactions
            with different bots within your system. By selecting specific date
            ranges and applying sorting options, you can easily view and compare
            the number of users engaging with various bots, helping you
            understand bot performance and user engagement patterns.
          </p>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 1 Navigate to Analytics
            </p>
            <p>
              Begin by accessing the Analytics menu from the main dashboard.
            </p>
          </div>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 2 Navigate to User Interaction
            </p>
            <p>
              Within Analytics, select User Interaction to explore user
              engagement data.
            </p>
          </div>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 3 Navigate to Bot User Count
            </p>
            <p>
              In the User Interaction section, click on Bot User Count to view
              user activity across different bots.
            </p>
          </div>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 4 Sort the data
            </p>
            <p>
              Use the sorting options to organize the data according to your
              preferences.
            </p>
          </div>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 5 Select the dates for filter the data
            </p>
            <p>
              Choose the desired date range to filter the data and display
              relevant bot user counts.
            </p>
          </div>
          <img src={bot_count} alt="bot_count" className="border mb-10" />

          <SubTitle
            subtitle="Messaging Analytics"
            hrefLink="messaging-analytics"
          />
          <p className="mb-5">
            In the Messaging Analytics section, you can explore comprehensive
            data on message interactions across your system. This section
            provides detailed insights into: All Messages, Date-Wise Messages,
            Application Messages, Bot Messages, Channel Messages, Broadcast
            Messages.
          </p>
          <p className="mb-5">
            Visualize these message types through color-coded charts, and refine
            your analysis by applying different criteria, selecting specific
            date ranges, and using sorting options. This allows you to
            understand messaging trends, optimize communication strategies, and
            monitor overall system performance.
          </p>

          <SubTitle subtitle="All Messaging" hrefLink="all-messaging" />
          <p className="mb-5">
            In the All-Messages section, you can access a comprehensive overview
            of all message interactions within your system. Filter these
            messages based on Application, Bot, Channel, and Channel Type to
            narrow down your analysis. Additionally, you can select specific
            date ranges to focus on messages exchanged during particular
            periods. This section provides a complete view of messaging
            activity, helping you monitor and optimize communication across
            various platforms.
          </p>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 1 Navigate to Analytics
            </p>
            <p>
              Begin by accessing the Analytics menu from the main dashboard.
            </p>
          </div>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 2 Navigate to Messaging Analytics
            </p>
            <p>
              Within Analytics, select Messaging Analytics to explore messaging
              data.
            </p>
          </div>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 3 Navigate to All Messages
            </p>
            <p>
              Click on All Messages to view a comprehensive list of all message
              interactions.
            </p>
          </div>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 4 Select the dates for filter the data
            </p>
            <p>
              Choose the desired date range to filter the data and focus on
              messages exchanged during that period.
            </p>
          </div>
          <img src={allmessages} alt="allmessages" className="border mb-10" />

          <SubTitle
            subtitle="Date Wish Messaging"
            hrefLink="date-wish-messaging"
          />
          <p className="mb-5">
            In the Date-Wise Messages section, you can analyze messaging
            activity over specific time periods. This section provides a
            breakdown of Total Messages, User Messages, Bot Messages, and Agent
            Messages. You can also sort the data by new or old messages,
            allowing you to focus on recent interactions or review historical
            data.
          </p>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 1 Navigate to Analytics
            </p>
            <p>
              Begin by accessing the Analytics menu from the main dashboard.
            </p>
          </div>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 2 Navigate to Messaging Analytics
            </p>
            <p>
              Within Analytics, select Messaging Analytics to explore messaging
              data.
            </p>
          </div>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 3 Navigate to Date Wise Messages
            </p>
            <p>
              Click on Date-Wise Messages to view detailed counts of messages
              categorized by date.
            </p>
          </div>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 4 Sort the data
            </p>
            <p>
              Use the sorting options to organize the data by new or old
              messages based on your analysis needs.
            </p>
          </div>
          <img
            src={date_wise_messages}
            alt="date_wise_messages"
            className="border mb-10"
          />

          <SubTitle
            subtitle="Application Messaging"
            hrefLink="application-messaging"
          />
          <p className="mb-5">
            In the Application Messages section, you can explore messaging
            activity across different applications within your system. This
            section provides detailed counts for Total Messages, User Messages,
            Bot Messages, and Agent Messages for each application. You can sort
            the data to focus on specific message types or time periods and
            filter the results based on the selected date range. This feature
            allows you to analyze messaging trends and performance across
            various applications effectively.
          </p>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 1 Navigate to Analytics
            </p>
            <p>
              Begin by accessing the Analytics menu from the main dashboard.
            </p>
          </div>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 2 Navigate to Messaging Analytics
            </p>
            <p>
              Within Analytics, select Messaging Analytics to explore messaging
              data.
            </p>
          </div>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 3 Navigate to Application Messages
            </p>
            <p>
              Click on Application Messages to view message counts across
              different applications.
            </p>
          </div>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 4 Sort the data
            </p>
            <p>
              Use the sorting options to organize the data by message type or
              other criteria.
            </p>
          </div>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 5 Select the dates for filter the data
            </p>
            <p>
              Choose the desired date range to filter the data and focus on
              messages exchanged during that period.
            </p>
          </div>
          <img
            src={application_messages}
            alt="application_messages"
            className="border mb-10"
          />

          <SubTitle subtitle="Bot Messaging" hrefLink="bot-messaging" />
          <p className="mb-5">
            In the Bot Messages section, you can analyze messaging activity
            specifically related to bots across different applications within
            your system. This section provides detailed counts for Total
            Messages, User Messages, Bot Messages, and Agent Messages for each
            application. You can sort the data to highlight specific message
            types and filter the results based on the selected date range. This
            feature allows you to monitor bot performance and understand how
            users interact with bots across various applications
          </p>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 1 Navigate to Analytics
            </p>
            <p>
              Begin by accessing the Analytics menu from the main dashboard.
            </p>
          </div>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 2 Navigate to Messaging Analytics
            </p>
            <p>
              Begin by accessing the Analytics menu from the main dashboard.
            </p>
          </div>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 3 Navigate to Bot Messages
            </p>
            <p>
              Click on Bot Messages to view message counts related to bots
              across different applications.
            </p>
          </div>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 4 Sort the data
            </p>
            <p>
              Use the sorting options to organize the data by message type or
              other criteria.
            </p>
          </div>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 5 Select the dates for filter the data
            </p>
            <p>
              Choose the desired date range to filter the data and focus on
              messages exchanged during that period.
            </p>
          </div>
          <img src={bot_messages} alt="bot_messages" className="border mb-10" />

          <SubTitle subtitle="Channel Messaging" hrefLink="channel-messaging" />
          <p className="mb-5">
            In the Channel Messages section, you can analyze messaging activity
            across different communication channels within your system. This
            section provides detailed counts for Total Messages, User Messages,
            Bot Messages, and Agent Messages for each channel. You can sort the
            data to focus on specific message types and filter the results based
            on the selected date range. This feature allows you to understand
            how different channels are being utilized and how effectively they
            are facilitating communication
          </p>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 1 Navigate to Analytics
            </p>
            <p>
              Begin by accessing the Analytics menu from the main dashboard.
            </p>
          </div>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 2 Navigate to Messaging Analytics
            </p>
            <p>
              Within Analytics, select Messaging Analytics to explore messaging
              data.
            </p>
          </div>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 3 Navigate to Channel Messages
            </p>
            <p>
              Click on Channel Messages to view message counts across different
              communication channels.
            </p>
          </div>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 4 Sort the data
            </p>
            <p>
              Use the sorting options to organize the data by message type or
              other criteria.
            </p>
          </div>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 5 Select the dates for filter the data
            </p>
            <p>
              Choose the desired date range to filter the data and focus on
              messages exchanged during that period.
            </p>
          </div>
          <img
            src={channel_messages}
            alt="channel_messages"
            className="border mb-10"
          />

          <SubTitle
            subtitle="Broadcast Messaging"
            hrefLink="broadcast-messaging"
          />
          <p className="mb-5">
            In the Broadcast Messages section, you can track and analyze the
            total number of broadcasted messages within your system. This
            section allows you to sort the data to focus on specific broadcast
            campaigns or message types. Additionally, you can filter the results
            by selecting a specific date range, enabling you to monitor the
            effectiveness and reach of your broadcast messages over time.
          </p>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 1 Navigate to Analytics
            </p>
            <p>
              Start by accessing the Analytics menu from the main dashboard.
            </p>
          </div>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 2 Navigate to Messaging Analytics
            </p>
            <p>
              Within Analytics, select Messaging Analytics to explore different
              messaging metrics.
            </p>
          </div>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 3 Navigate to Broadcast Messages
            </p>
            <p>
              Click on Broadcast Messages to view the total number of
              broadcasted messages.
            </p>
          </div>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 4 Sort the data
            </p>
            <p>
              Use the sorting options to organize the data based on your
              criteria.
            </p>
          </div>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 5 Select the dates for filter the data
            </p>
            <p>
              Choose the desired date range to filter the data and focus on
              broadcast messages sent during that period.
            </p>
          </div>
          <img
            src={broadcast_messages}
            alt="broadcast_messages"
            className="border mb-10"
          />

          <div className="flex justify-between">
            <PreviousBtn path="/live-chat" btnText="Live Chat" />
            <NextBtn path="/contacts" btnText="Contacts" />
          </div>
        </div>
        <RightBar rightBarMenu={rightBarMenu} />
      </div>
    </>
  );
}

export default Analytics;
