import React from "react";
import NextBtn from "../../components/NextBtn";
import PreviousBtn from "../../components/PreviousBtn";
import createBot from "../../images/quickStartImage/createBot.gif";
import Title from "../../components/Title";

function LLM() {
  return (
    <>
      <div className="lg:flex gap-6 sm:p-8 p-5 text-gray-600">
        <div className="flex-1">
          <Title title="LLM" />

          <p className="mb-5">
            Utilizing XUnified's sophisticated Bot Configuration feature enables
            enterprises to streamline operations, boost customer self-service
            functionalities, and deliver uninterrupted support to their
            business. This encompasses defining responses, comprehending user
            inputs, and configuring the bot's actions in response to particular
            triggers or scenarios.
          </p>
          <div className="mb-8">
            <p className="mb-2 text-lg text-black font-medium">
              Step - 1 Navigate to Bot Configuration.
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              Step - 2 Click on Create Bot, on the top right corner.
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              As you click on create bot, you will find three options:
              Dialogflow, LLM, and AI Studio. Select as per your requirement.
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              Step - 3 Click LLM.
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              It will open LLM workspace.
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              Step - 4 Click on Create LLM.
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              Step - 5 Enter bot name and Select AI Model from the drop-down.
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              Step - 6 Select model type either Knowledgebase or Prompt, Select
              Secret Key, and Click Next.
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              Step - 7 Select Source Type, enter Value, Save Source, and click
              Continue.
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              Step - 8 Review and click Train Model.
            </p>
            <p className="mb-2 text-lg text-black font-medium">
              It will create the model and reflect in LLM workspace.
            </p>
            <img src={createBot} alt="createBot" className="border" />
          </div>

          <div className="flex justify-between">
            <PreviousBtn
              path="/bot-configuration/dialogflow"
              btnText="Dialogflow"
            />
            <NextBtn path="/bot-configuration/ai-studio" btnText="AI Studio" />
          </div>
        </div>
      </div>
    </>
  );
}

export default LLM;
