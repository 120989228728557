import React from "react";
import Title from "../../components/Title";
import SubTitle from "../../components/SubTitle";
import RightBar from "../../components/RightBar";
import Topbar from "../../components/Topbar";
import ScrollActive from "../../components/ScrollActive";
import PreviousBtn from "../../components/PreviousBtn";
import NextBtn from "../../components/NextBtn";
import myprofile from "../../images/settingsimg/myprofile.gif";
import companyprofile from "../../images/settingsimg/companyprofile.gif";
import users from "../../images/settingsimg/users.gif";
import apikeygif from "../../images/botconfigimg/APIkey.gif";
import deleteapikeygif from "../../images/botconfigimg/APIkeydelete.gif";

const rightBarMenu = [
  {
    tolink: "my-profile",
    submenutext: "My Profile",
  },
  {
    tolink: "company-profile",
    submenutext: "Company Profile",
  },
  {
    tolink: "users",
    submenutext: "Users",
    children: [
      {
        tolink: "invite-users",
        submenutext: "Invite Users",
      },
      {
        tolink: "resend-invite-users",
        submenutext: "Resend Invite Users",
      },
      {
        tolink: "delete-invite-users",
        submenutext: "Delete Invite Users",
      },
    ],
  },
  {
    tolink: "api-key",
    submenutext: "API Key",
    children: [
      {
        tolink: "adding-api-key",
        submenutext: "Adding API Key",
      },
      {
        tolink: "deleting-api-key",
        submenutext: "Deleting API Key",
      },
    ],
  },
];

function Settings() {
  return (
    <>
      <ScrollActive rightBarMenu={rightBarMenu} />
      <Topbar rightBarMenu={rightBarMenu} />

      <div className="lg:flex gap-6 sm:p-8 p-5 text-gray-600">
        <div className="flex-1">
          <Title title="Settings" />
          <p className="mb-2">
            Welcome to the XUnified Settings! Here, you can manage all essential
            configurations to tailor your experience. Update your personal
            information under My Profile, customize your organization's details
            in the Company Profile, manage team members in Users, generate and
            monitor your API Key, and review or update your Subscription
            preferences. Ensure your settings are up to date to maximize the
            benefits of XUnified.
          </p>

          <SubTitle subtitle="My Profile" hrefLink="my-profile" />
          <p className="mb-2">
            In the My Profile section, you can personalize your account by
            updating various details. Easily change your profile picture and
            keep your personal information and address up to date. If you need
            to update your password, simply click the Edit button to make
            changes. Once you're satisfied with your updates, click Save to
            ensure your information is securely stored.
          </p>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 1 Navigate to Settings
            </p>
            <p>Start by accessing the Settings menu from the main dashboard.</p>
          </div>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 2 Navigate to My Profile
            </p>
            <p>
              Within Settings, click on My Profile to view your personal account
              details.
            </p>
          </div>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 3 Click on Upload button to change the profile picture
            </p>
            <p>
              To change your profile picture, click on the Upload button and
              select a new image from your device.
            </p>
          </div>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 4 Click on Edit to change Personal Information, Address
              Information, and Change Password
            </p>
            <p>
              To update your Personal Information, Address Information, or to
              Change Password, click on the Edit button next to the relevant
              section.
            </p>
          </div>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 5 Click on Save after edit the information
            </p>
            <p>
              After making the necessary changes, click Save to ensure all your
              updated information is securely stored.
            </p>
          </div>
          <img src={myprofile} alt="myprofile" className="border mb-8" />

          <SubTitle subtitle="Company Profile" hrefLink="company-profile" />
          <p className="mb-2">
            In the Company Profile section, you can manage and update your
            organization's details. Easily change the company profile picture
            and keep your company information current. To make any edits, simply
            click the Edit button. Once you've made your changes, click Save to
            ensure your updates are applied and securely stored.
          </p>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 1 Navigate to Settings
            </p>
            <p>Start by accessing the Settings menu from the main dashboard.</p>
          </div>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 2 Navigate to Company Profile
            </p>
            <p>
              Within Settings, select Company Profile to view and manage your
              organization's details.
            </p>
          </div>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 3 Click on Upload button to change the Company profile
              picture
            </p>
            <p>
              To change the company profile picture, click on the Upload button
              and select a new image from your device.
            </p>
          </div>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 4 Click on Edit to change Company profile
            </p>
            <p>To update the company information, click on the Edit button.</p>
          </div>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 5 Click on Save after edit the information
            </p>
            <p>
              After making the necessary changes, click Save to ensure all your
              updated information is securely stored.
            </p>
          </div>
          <img
            src={companyprofile}
            alt="companyprofile"
            className="border mb-8"
          />

          <SubTitle subtitle="API Key" hrefLink="api-key" />
          <SubTitle subtitle="Adding API Key" hrefLink="adding-api-key" />
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 1 Navigate to Settings
            </p>
            <p>
              Look for a "Settings" option, usually found in the navigation
              menu.
            </p>
          </div>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 2 Navigate to API Key
            </p>
            <p>
              Within the Settings page, look for a tab "API Key". This area is
              dedicated to handling API keys, including adding, deleting, or
              viewing existing keys.
            </p>
          </div>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 3 Click on Add New Key
            </p>
            <p>
              Find and click on the "Add New Key" button. This action will start
              the process of creating a new API key for your application.
            </p>
          </div>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 4 Add Name, Secret Key and select Platform, in Add New API
              Key pop-up
            </p>
            <p className="mb-2">
              A pop-up form will appear where you'll need to provide the
              following information:
            </p>
            <ul className="mb-5 pl-5 space-y-2">
              <li className="list-disc">
                <span className="text-black font-medium">Secret Key:</span>{" "}
                Input the secret key provided by OpenAI. This key is essential
                for authenticating your requests to the OpenAI API.
              </li>
              <li className="list-disc">
                <span className="text-black font-medium">Select Platform:</span>{" "}
                Choose the platform or application where you intend to use the
                API key. This could be a specific environment like "Development"
                or "Production."
              </li>
            </ul>
          </div>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 5 Click on Add
            </p>
            <p className="mb-2">
              Once you have filled out all the necessary information, click the
              "Add" button to finalize the creation of the new API key. This
              action will save your key details and make the key available for
              use.
            </p>
          </div>
          <img src={apikeygif} alt="apikeygif" className="border mb-10" />

          <SubTitle subtitle="Deleting API Key" hrefLink="deleting-api-key" />
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 1 Navigate to Settings
            </p>
            <p>
              Look for a "Settings" option, usually found in the navigation
              menu.
            </p>
          </div>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 2 Navigate to API Key
            </p>
            <p>
              Within the Settings page, look for a tab "API Key". This area is
              dedicated to handling API keys, including adding, deleting, or
              viewing existing keys.
            </p>
          </div>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 3 Click on Delete
            </p>
            <p>
              In the list of API keys, identify the key you want to delete.
              Click on the "Delete" icon next to the API key you wish to remove.
              This action signals your intent to delete the key.
            </p>
          </div>
          <div className="mb-5">
            <p className="font-medium text-gray-800 text-lg">
              Step - 4 It will ask for the confirmation in pop-up, Click on
              Delete button
            </p>
            <p className="mb-2">
              A pop-up window will appear, asking you to confirm the deletion.
              Click on the "Delete" button within the pop-up to confirm your
              decision. By doing this, you are permanently removing the API key
              from your account.
            </p>
          </div>
          <img
            src={deleteapikeygif}
            alt="deleteapikeygif"
            className="border mb-10"
          />

          <div className="flex justify-between">
            <PreviousBtn path="/logs" btnText="Logs" />
            <NextBtn path="/support" btnText="Support" />
          </div>
        </div>
        <RightBar rightBarMenu={rightBarMenu} />
      </div>
    </>
  );
}

export default Settings;
