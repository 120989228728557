import { IconLink } from "@tabler/icons-react";
import React from "react";
import { useDispatch } from "react-redux";
import { activemenu } from "../appStore/MenuSlice";

function SubTitle({ subtitle, hrefLink }) {
  const dispatch = useDispatch();
  const titlelink = (hrefLinkTitle) => {
    if (hrefLink === hrefLinkTitle) {
      dispatch(activemenu(hrefLink));
    }
  };
  return (
    <h3 id={hrefLink} className="xl:scroll-mt-[88px] scroll-mt-[153px] group ">
      <a
        href={`#${hrefLink}`}
        className="py-4 font-semibold text-2xl text-gray-600 inline-block w-fit"
        onClick={() => titlelink(hrefLink)}
      >
        {subtitle}{" "}
        <span className="inline-block relative top-[3px] pl-2">
          <IconLink
            stroke={2.5}
            className=" text-gray-600 hidden group-hover:block"
          />
        </span>
      </a>
    </h3>
  );
}

export default SubTitle;
