import React from "react";

function Title({ title }) {
  return (
    <h2 className="font-semibold text-4xl text-gray-800 mb-4 leading-[46px] ">
      {title}
    </h2>
  );
}

export default Title;
