import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { activemenu } from "../appStore/MenuSlice";

function RightBar({ rightBarMenu }) {
  const scrollLinkURL = useSelector((state) => state.menu.scrollLinkURL);
  const dispatch = useDispatch();

  const renderRightBarMenu = (data, pl = 0) => {
    const el = [];
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      const design = (
        <li
          key={element.tolink}
          className={`text-gray-500 hover:text-black cursor-pointer text-sm`}
          style={{
            paddingLeft: pl,
          }}
        >
          <a
            href={`#${element.tolink}`}
            className={`block ${
              element.tolink === scrollLinkURL ? "activemenu" : ""
            }`}
            onClick={() => dispatch(activemenu(element.tolink))}
          >
            {element.submenutext}
          </a>
        </li>
      );
      el.push(design);
      if (element?.children && element?.children?.length > 0) {
        el.push(...renderRightBarMenu(element?.children, pl + 20));
      }
    }
    return el;
  };

  return (
    <div className="w-[260px] xl:block hidden">
      <div className="sticky top-[110px]">
        <p className="text-gray-600 pb-1 font-medium border-b border-gray-400">
          On this page
        </p>
        <ul className="py-4 space-y-3 pr-[6px] max-h-[calc(100vh-140px)] overflow-auto">
          {renderRightBarMenu(rightBarMenu)}
        </ul>
      </div>
    </div>
  );
}

export default RightBar;
