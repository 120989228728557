import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import "./App.css";
import routeArry from "./routeArry";
import Sidebar from "./components/Sidebar";
import Navbar from "./components/Navbar";
import { useDispatch } from "react-redux";
import { toogleMenuOff } from "./appStore/MenuSlice";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

function App() {
  const dispatch = useDispatch();

  const [windowSize, setWindowSize] = useState();

  const renderElement = ({ element, title = "" }) => {
    return (
      <>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        {element}
      </>
    );
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };
    if (windowSize > 1024) {
      dispatch(toogleMenuOff());
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [windowSize]);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Navbar />
      <section className="bg-white">
        <div className="flex max-w-[1440px] mx-auto">
          <Sidebar />
          <div className="lg:w-[calc(100%-270px)] lg:ml-[270px] w-full">
            <Routes>
              <Route path="/" element={<Navigate to="/docs" />} />
              {routeArry.map((item, index) => (
                <Route
                  path={item.path}
                  element={renderElement(item)}
                  key={index}
                />
              ))}
            </Routes>
          </div>
        </div>
      </section>
    </BrowserRouter>
  );
}

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export default App;
