import { IconChevronLeft } from "@tabler/icons-react";
import React from "react";
import { Link } from "react-router-dom";

function PreviousBtn({ path, btnText }) {
  return (
    <Link
      to={path}
      className="py-2 px-3 rounded-lg hover:bg-gray-200 w-[200px]"
    >
      <div className="flex gap-2 items-center text-indigo-600 text-sm font-medium">
        <span>
          <IconChevronLeft size={18} />
        </span>
        <div>
          <p>Previous</p>
          <p className="text-gray-600 text-sm font-medium">{btnText}</p>
        </div>
      </div>
    </Link>
  );
}

export default PreviousBtn;
