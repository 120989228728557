import React from "react";
import ScrollActive from "../../components/ScrollActive";
import Topbar from "../../components/Topbar";
import Title from "../../components/Title";
import SubTitle from "../../components/SubTitle";
import PreviousBtn from "../../components/PreviousBtn";
import NextBtn from "../../components/NextBtn";
import RightBar from "../../components/RightBar";

const rightBarMenu = [
  {
    tolink: "step-1-navigate-to-omni-channel",
    submenutext: "Step - 1 Navigate to Omni Channel",
  },
  {
    tolink: "step-2-navigate-to-twilio",
    submenutext: "Step - 2 Navigate to Twilio",
  },
  {
    tolink: "step-3-click-connect",
    submenutext: "Step - 3 Click Connect",
  },
  {
    tolink: "step-4-click-continue-with-twilio-in-set-up-twilio",
    submenutext: "Step - 4 Click Continue with Twilio, in Set up Twilio",
  },
  {
    tolink: "step-5-add-account-sid-auth-token",
    submenutext: "Step - 5 Add Account SID, Auth Token",
  },
  {
    tolink: "step-6-click-verify-details-and-get-phone-numbers",
    submenutext: "Step - 6 Click Verify details and get phone numbers",
  },
  {
    tolink: "step-7-add-twilio-channel-name-once-it-verified",
    submenutext: "Step - 7 Add Twilio channel name, once it verified",
  },
  {
    tolink: "step-8-select-the-twilio-phone-number",
    submenutext: "Step - 8 Select the Twilio phone number",
  },
  {
    tolink: "step-9-click-save-your-twilio-configuration",
    submenutext: "Step - 9 Click Save your Twilio Configuration",
  },
  {
    tolink: "step-10-add-twilio-webhook-callback-url",
    submenutext: "Step - 10 Add Twilio Webhook callback URL",
  },
  {
    tolink: "step-11-click-finish",
    submenutext: "Step - 11 Click Finish",
  },
];

function TwilioIntegration() {
  return (
    <>
      <ScrollActive rightBarMenu={rightBarMenu} />
      <Topbar rightBarMenu={rightBarMenu} />

      <div className="lg:flex gap-6 sm:p-8 p-5 text-gray-600">
        <div className="flex-1">
          <Title title="Twilio Integration" />

          <p className="mb-2 text-lg text-black font-medium">
            Before You Start
          </p>
          <p className="mb-5">
            Before initiating the integration process with Twilio through
            XUnified, it's essential for users to gather the following
            information
          </p>
          <p className="mb-5">
            <span className="font-medium text-gray-800 text-lg">
              1. Twilio Account SID (Service Identifier):
            </span>{" "}
            This is a unique identifier assigned to each Twilio account upon
            registration. It serves as a primary identifier for accessing and
            managing various Twilio services and resources.
          </p>
          <p className="mb-5">
            <span className="font-medium text-gray-800 text-lg">
              2. Twilio Auth Token (Authentication Token):
            </span>{" "}
            The Auth Token serves as a secure authentication key for accessing
            Twilio's APIs and services. It is paired with the Account SID to
            authenticate and authorize requests made to the Twilio platform.
          </p>
          <p className="mb-5">
            <span className="font-medium text-gray-800 text-lg">
              3. Twilio Purchased Phone Number:
            </span>{" "}
            Users need to have at least one phone number purchased or
            provisioned through their Twilio account. This phone number will be
            utilized for communication purposes, such as sending and receiving
            messages or making and receiving calls through Twilio's services.
          </p>
          <p className="mb-5">
            Gathering these details beforehand ensures a smooth integration
            process and enables XUnified to seamlessly connect with Twilio's
            functionalities, facilitating effective communication and engagement
            channels for users and their customers.{" "}
          </p>

          <div className="mb-8">
            <SubTitle
              subtitle="Step - 1 Navigate to Omni Channel"
              hrefLink="step-1-navigate-to-omni-channel"
            />
            <p className="mb-2">
              Access the Omni Channel section in the XUnified dashboard.
            </p>
            {/* <img src={wsa1} alt="wsa1" className="border" /> */}
          </div>

          <div className="flex justify-between">
            <PreviousBtn
              path="/omnichannel/telegram-integration"
              btnText="Telegram Integration"
            />
            <NextBtn
              path="/omnichannel/textMagic-integration"
              btnText="TextMagic Integration"
            />
          </div>
        </div>

        <RightBar rightBarMenu={rightBarMenu} />
      </div>
    </>
  );
}

export default TwilioIntegration;
