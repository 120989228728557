import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { activemenu } from "../appStore/MenuSlice";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { IconChevronRight } from "@tabler/icons-react";

function Topbar({ rightBarMenu }) {
  const dispatch = useDispatch();
  const scrollLinkURL = useSelector((state) => state.menu.scrollLinkURL);
  const scrollLinkText = useSelector((state) => state.menu.scrollLinkText);

  const renderRightBarMenu = (data, pl = 0) => {
    const el = [];
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      const design = (
        <MenuItem key={element.tolink}>
          <div
            className="text-gray-500 hover:text-black cursor-pointer text-sm"
            style={{
              paddingLeft: pl,
            }}
          >
            <a
              href={`#${element.tolink}`}
              className={`block p-2 ${
                element.tolink === scrollLinkURL ? "activemenu" : ""
              }`}
              onClick={() => dispatch(activemenu(element.tolink))}
            >
              {element.submenutext}
            </a>
          </div>
        </MenuItem>
      );
      el.push(design);
      if (element?.children && element?.children?.length > 0) {
        el.push(...renderRightBarMenu(element?.children, pl + 20));
      }
    }
    return el;
  };

  return (
    <div className="p-4 border-b border-gray-200 sticky top-[77px] bg-white xl:hidden block z-10">
      <Menu>
        <div className="flex items-center gap-4">
          <MenuButton className="inline-flex items-center gap-2 rounded-md bg-gray-100 py-1.5 px-3 focus:outline-none border text-sm whitespace-nowrap">
            On this page
            <IconChevronRight size={16} />
          </MenuButton>
          <p className="text-sm text-gray-700 line-clamp-1">{scrollLinkText}</p>
        </div>

        <MenuItems className="absolute shadow-lg left-4 rounded-md border p-1 transition duration-100 ease-out focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0 z-[1000] bg-white  w-[calc(100%-32px)] max-h-[calc(100vh-140px)] overflow-auto">
          {renderRightBarMenu(rightBarMenu)}
        </MenuItems>
      </Menu>
    </div>
  );
}

export default Topbar;
