import React from "react";
import {
  IconApps,
  IconBinaryTree2,
  IconBolt,
  IconBook,
  IconChartBar,
  IconChevronDown,
  IconDashboard,
  IconDoor,
  IconHelp,
  IconHome,
  IconListDetails,
  IconMessageChatbot,
  IconRobotFace,
  IconSettings,
  IconSpeakerphone,
  IconUserCircle,
  IconX,
} from "@tabler/icons-react";
import { NavLink } from "react-router-dom";
import logo from "../images/Logo.png";
import { useDispatch, useSelector } from "react-redux";
import { toogleMenuOff } from "../appStore/MenuSlice";

const MainMenu = [
  {
    id: 1,
    label: "Getting Started",
    childrenItem: [
      {
        id: 11,
        name: "XUnified Docs",
        icon: <IconHome size={20} stroke={1.8} />,
        path: "/docs",
      },
      {
        id: 12,
        name: "Quick Start",
        icon: <IconBook size={20} stroke={1.8} />,
        path: "/quick-start",
      },
      {
        id: 13,
        name: "Sign Up & Sign In",
        icon: <IconDoor size={20} stroke={1.8} />,
        path: "/signup-signin",
      },
      {
        id: 14,
        name: "Fundamentals",
        icon: <IconBolt size={20} stroke={1.8} />,
        path: "/fundamentals",
      },
    ],
  },
  {
    id: 2,
    label: "Build & Manage",
    childrenItem: [
      {
        id: 21,
        name: "Dashboard",
        icon: <IconDashboard size={20} stroke={1.8} />,
        path: "/dashbord",
      },
      {
        id: 22,
        name: "Applications",
        icon: <IconApps size={20} stroke={1.8} />,
        path: "/applications",
      },
      {
        id: 23,
        name: "Omnichannel",
        icon: <IconBinaryTree2 size={20} stroke={1.8} />,
        path: "/omnichannel",
        subchildrenItem: [
          {
            id: 211,
            name: "WhatsApp Integration",
            path: "/omnichannel/whatsApp-integration",
          },
          {
            id: 212,
            name: "Messenger Integration",
            path: "/omnichannel/messenger-integration",
          },
          {
            id: 213,
            name: "Instagram Integration",
            path: "/omnichannel/instagram-integration",
          },
          {
            id: 214,
            name: "Telegram Integration",
            path: "/omnichannel/telegram-integration",
          },
          {
            id: 215,
            name: "Twilio Integration",
            path: "/omnichannel/twilio-integration",
          },
          {
            id: 216,
            name: "TextMagic Integration",
            path: "/omnichannel/textMagic-integration",
          },
        ],
      },
      {
        id: 24,
        name: "Bot Configuration",
        icon: <IconRobotFace size={20} stroke={1.8} />,
        path: "/bot-configuration",
        subchildrenItem: [
          {
            id: 311,
            name: "API Key",
            path: "/bot-configuration/api-key",
          },
          {
            id: 312,
            name: "Dialogflow",
            path: "/bot-configuration/dialogflow",
          },
          {
            id: 313,
            name: "LLM",
            path: "/bot-configuration/llm",
          },
          {
            id: 314,
            name: "AI Studio",
            path: "/bot-configuration/ai-studio",
          },
        ],
      },
      {
        id: 25,
        name: "Campaigns",
        icon: <IconSpeakerphone size={20} stroke={1.8} />,
        path: "/campaigns",
      },
      {
        id: 26,
        name: "Live Chat",
        icon: <IconMessageChatbot size={20} stroke={1.8} />,
        path: "/live-chat",
      },
    ],
  },
  {
    id: 3,
    label: "Quick Link",
    childrenItem: [
      {
        id: 31,
        name: "Analytics",
        icon: <IconChartBar size={20} stroke={1.8} />,
        path: "/analytics",
      },
      {
        id: 32,
        name: "Contacts",
        icon: <IconUserCircle size={20} stroke={1.8} />,
        path: "/contacts",
      },
      {
        id: 33,
        name: "Logs",
        icon: <IconListDetails size={20} stroke={1.8} />,
        path: "/logs",
      },
      {
        id: 34,
        name: "Settings",
        icon: <IconSettings size={20} stroke={1.8} />,
        path: "/settings",
      },
      {
        id: 35,
        name: "Support",
        icon: <IconHelp size={20} stroke={1.8} />,
        path: "/support",
      },
    ],
  },
];

function Sidebar() {
  const { showToogleMenu } = useSelector((state) => state.menu);
  const dispatch = useDispatch();
  return (
    <>
      {showToogleMenu && (
        <div
          className="bg-black/50 fixed top-0 left-0 w-screen h-screen z-[999]"
          onClick={() => dispatch(toogleMenuOff())}
        ></div>
      )}
      <div
        className={`lg:h-[calc(100vh-77px)] h-screen w-[270px] border-r border-gray-100 overflow-auto fixed bg-white lg:shadow-none shadow-2xl transition-all z-[1000] lg:top-[unset] top-0 lg:left-[unset] -left-[270px] ${
          showToogleMenu ? "left-0" : ""
        }`}
      >
        <div className="flex items-center justify-between gap-2 sticky top-0 bg-white lg:hidden px-4 py-5">
          <div className="flex gap-2 items-center">
            <img src={logo} alt="logo" className="w-[110px]" />
            <div>
              <p className="text-gray-900 text-xs py-[2px] px-[10px] border border-gray-200 rounded-full">
                Docs
              </p>
            </div>
          </div>
          <IconX onClick={() => dispatch(toogleMenuOff())} />
        </div>
        <div className="grid gap-y-6 px-4 py-5">
          {MainMenu.map((item, index) => (
            <div key={item.id} className="grid gap-y-2">
              {item.label && (
                <p className="text-xs font-medium text-gray-900 px-2">
                  {item.label}
                </p>
              )}
              {item.childrenItem.map((menu, menuindex) => (
                <React.Fragment key={menu.id}>
                  {!menu?.subchildrenItem?.length > 0 ? (
                    <li
                      className={`flex items-center gap-2 `}
                      onClick={() => {
                        dispatch(toogleMenuOff());
                      }}
                    >
                      <NavLink
                        to={menu.path}
                        className={({ isActive }) =>
                          "flex items-center w-full gap-2 p-2 overflow-hidden transition-all hover:text-gray-900 rounded-md " +
                          (isActive
                            ? " text-gray-900 bg-gray-100"
                            : "text-gray-500 ")
                        }
                      >
                        <div>{menu.icon}</div>
                        <p className="text-sm font-normal">{menu.name}</p>
                      </NavLink>
                      {menu?.subchildrenItem?.length > 0 && (
                        <div>
                          <IconChevronDown size={20} stroke={1.8} />
                        </div>
                      )}
                    </li>
                  ) : (
                    <ul>
                      <li className="flex items-center gap-2 p-2 overflow-hidden transition-all text-gray-500">
                        <div className="flex items-center w-full gap-2">
                          <div>{menu.icon}</div>
                          <p className="text-sm font-normal">{menu.name}</p>
                        </div>
                      </li>
                      <div>
                        <ul className="pl-[28px]">
                          {menu?.subchildrenItem?.map(
                            (submenu, submenuindex) => (
                              <li
                                key={submenu.id}
                                onClick={() => {
                                  dispatch(toogleMenuOff());
                                }}
                                className="border-l border-gray-200 py-1"
                              >
                                <NavLink
                                  to={submenu.path}
                                  className={({ isActive }) =>
                                    "text-sm font-normal flex -ml-[1px] items-center gap-2 p-2 overflow-hidden transition-all hover:text-gray-900  border-l border-gray-200 hover:border-gray-800 " +
                                    (isActive
                                      ? "text-gray-900 border-gray-800 bg-gray-100 rounded-r-md"
                                      : "text-gray-500")
                                  }
                                >
                                  {submenu.name}
                                </NavLink>
                              </li>
                            )
                          )}
                        </ul>
                      </div>
                    </ul>
                  )}
                </React.Fragment>
              ))}
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Sidebar;
